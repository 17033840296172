import React from 'react';

interface DotsProps {
  size?: string;
  dotSize?: string;
  dotCount?: number;
  color?: string;
  speed?: string;
  spread?: string;
}

interface DotProps {
  i: number;
}

const Dot: React.FC<DotProps> = ({ i }) => (
  <div style={{ '--i': i } as React.CSSProperties} className="dot"></div>
);

const DottedLoading: React.FC<DotsProps> = ({
  size = '64px',
  dotSize = '6px',
  dotCount = 6,
  color = 'var(--dark)',
  speed = '1s',
  spread = '60deg',
}) => {
  const dots = Array.from({ length: dotCount }, (_, index) => index);

  return (
    <div
      style={{
        '--size': size,
        '--dot-size': dotSize,
        '--dot-count': dotCount,
        '--color': color,
        '--speed': speed,
        '--spread': spread,
      } as React.CSSProperties}
      className="dots"
    >
      {dots.map((i) => (
        <Dot key={i} i={i} />
      ))}
    </div>
  );
};

export default DottedLoading;