import React, { useContext, useRef, useState } from 'react'
import { ImPhone } from 'react-icons/im'
import { RiMailOpenFill, RiInstagramFill } from 'react-icons/ri'
import { FaFacebookF, FaLinkedinIn, FaYoutube } from 'react-icons/fa'
import socialMedia from '../../../assets/images/social/social-media-1.png'
import { WindowWidthContext, ContextProps } from '../../contexts/WindowWidthProvider'
import { Link } from 'react-router-dom'
import useClickOutside from '../../hooks/useClickOutside'
import { handleImageLinkDrage } from '../../helpers/ApiRequest'
import './TopHeader.css'

const TopHeader: React.FC = () => {
    const { windowWidth } = useContext<ContextProps>(WindowWidthContext)
    const [isSocialMedia, setIsSocialMedia] = useState(false)

    const elementRef = useRef<HTMLDivElement>(null);
    const blockRef = useRef<HTMLDivElement>(null);

    const handleShoppingCartBoxClose = () => {
        setIsSocialMedia(false)
    };

    useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef);

    return (
        <div className='top-header-container d-flex justify-content-between align-items-center'>
            <div className="containerr top-container w-100 mx5">
                <div className={`row align-items-center ${windowWidth > 578 ? 'mx-2' : ''}`}>
                    <div className={`col-lg-6 col-md-6 col-sm-7 col-10 align-items-center ${windowWidth < 770 ? 'textcenter' : 'text-start d-inline-flex ps-4'} ${windowWidth < 578 ? 'dcontents' : ''} `}>
                        <div className="info d-inline-flex m-info mb-1">
                            <Link to='tel:0661297515' className={`nav-link top-header-text ${windowWidth < 578 ? 'me-2' : 'me-4'}`}><ImPhone className='icon' /> 0661297515</Link>
                            <Link to='mailto:info@hbeauty.ma' className='nav-link top-header-text'><RiMailOpenFill className='icon' /> info@hbeauty.ma</Link>
                        </div>
                    </div>

                    {/* <div className={`col-lg-6 col-md-6 col-sm-6 col-12 align-items-center ${windowWidth < 770 ? 'text-center' : 'text-center d-inline-fle ps4'}`}>
                        <p className='clr-orange'>Livraison grauite à partir de 300 DH.</p>
                    </div> */}

                    <div className={`col-lg-6 col-md-6 col-sm-5 col-2 align-items-center time ${windowWidth < 770 ? 'textcenter text-end' : 'text-end'} ${windowWidth > 578 ? 'mb-1' : 'mt-1 p-0'} `}>
                        <div className="social d-inline-flex pt-sm-1 m-social">
                            {
                                windowWidth < 578 ?
                                    <React.Fragment>
                                        <div ref={blockRef}>
                                            <img
                                                src={socialMedia}
                                                alt="social media"
                                                className='social-media-image c-pointer'
                                                onClick={() => setIsSocialMedia(!isSocialMedia)}
                                                onDragStart={handleImageLinkDrage}
                                            />
                                            {/* <CgMenuGridO className='fs-1 c-ponter social-media-grid-icon ' onClick={() => setIsSocialMedia(!isSocialMedia)} /> */}
                                        </div>

                                        {
                                            // isSocialMedia ?
                                            <div ref={elementRef} className={`social-media-content ${isSocialMedia ? 'is-social-media-open' : 'is-social-media-close'}`}>
                                                <Link to='https://www.facebook.com/people/HBeauty/100051215415162/' target='_blank' rel="noreferrer" className='social-icon fs-4 me-3 nav-link top-header-text'><FaFacebookF className='icon' /></Link>
                                                <Link to='https://www.instagram.com/hbeauty_maroc/' target='_blank' rel="noreferrer" className='social-icon fs-4 me-3 nav-link top-header-text'><RiInstagramFill className='icon' /></Link>
                                                {/* <Link to='https://hbeauty.ma' target='_blank' rel="noreferrer" className='social-icon fs-4 me-3 me-sm-4 nav-link top-header-text'><FaLinkedinIn className='icon' /></Link> */}
                                                <Link to='https://www.youtube.com/@hbeauty9120' target='_blank' rel="noreferrer" className='social-icon fs-4 me-3 me-sm-4 nav-link top-header-text'><FaYoutube className='icon' /></Link>
                                            </div>
                                            // :
                                            // ''
                                        }
                                    </React.Fragment>
                                    :
                                    <>
                                        <Link to='https://www.facebook.com/people/HBeauty/100051215415162/' target='_blank' rel="noreferrer" className='social-icon fs-x me-3 nav-link top-header-text'><FaFacebookF className='icon' /></Link>
                                        <Link to='https://www.instagram.com/hbeauty_maroc/' target='_blank' rel="noreferrer" className='social-icon fs-x me-3 nav-link top-header-text'><RiInstagramFill className='icon' /></Link>
                                        {/* <Link to='https://hbeauty.ma' target='_blank' rel="noreferrer" className='social-icon fs-x me-3 nav-link top-header-text'><FaLinkedinIn className='icon' /></Link> */}
                                        <Link to='https://www.youtube.com/@hbeauty9120' target='_blank' rel="noreferrer" className='social-icon fs-x me-3 nav-link top-header-text'><FaYoutube className='icon' /></Link>
                                    </>
                            }

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default TopHeader;
