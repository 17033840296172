import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import * as yup from "yup";
import { useFormik } from "formik";
import InputBox from '../../components/InputBox/InputBox';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import axios from 'axios';
import DottedLoading from '../../components/Loading/DottedLoading';
import { ErrorNotify, notify } from '../../helpers/Toastify';
import { Helmet } from 'react-helmet-async';
import './loginStyle.css'


const ResetPassword: React.FC = (props) => {

  // Global
  const navigate = useNavigate();
  const user_token = localStorage.getItem('hb_user_token');
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useParams();


  useEffect(() => {
    if (user_token) {
      navigate('/my-account');
    }
  }, [user_token]);

  const validationSchema = yup.object({
    password: yup.string().required("Veuillez saisir votre mot de passe"),
    confirmPassword: yup.string().required('Veuillez confirmer votre mot de passe')
      .oneOf([yup.ref('password'), ''], 'Veuillez confirmer votre mot de passe'),
  });


  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('password', values.password);

        const response = await axios.post(`https://api.hbeauty.ma/reset_password/${token}`, formData);

        if (response.status === 200) {
          setIsLoading(false)
          navigate('/login');
          notify(await response.data.message);
        }

      } catch (error) {
        ErrorNotify("Aucun utilisateur trouvé avec l'adresse e-mail");
      }
    },
  });


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <>
      <Helmet>
        <title>Réinitialiser le mot de passe</title>
      </Helmet>
      <TopHeader />
      <Navbar />
      <HeaderContainer
        className="fw-bold fs-1 mt-5"
        title='Réinitialiser le mot de passe'
      />
      <div className="container d-flex justify-content-center align-items-center pt-5">

        <form onSubmit={handleSubmit} className='d-flex justify-content-center align-items-center'>
          <div className="login-form">
            <div className="row d-flex justify-content-start align-items-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <InputBox
                  label="Mot de passe"
                  spanValue="Mot de passe"
                  name="password"
                  type="password"
                  value={values.password}
                  className={touched.password && errors.password ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <InputBox
                  label="Confirmer le mot de passe"
                  spanValue="Confirmer le mot de passe"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  className={touched.confirmPassword && errors.confirmPassword ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-5 d-flex justify-content-start align-items-center">
                {
                  isLoading ?
                    <DottedLoading />
                    :
                    <SubmitButton
                      className="mt-1 px-5"
                      btnLabel="Réinitialiser"
                    />
                }

              </div>

            </div>

          </div>
        </form>

      </div>
    </>
  );
};

export default ResetPassword;