import { ProductSwiperProps } from "../../../helpers/Props";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './ProductSwiper.css'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useProductIds } from "../../../hooks/useProductIds";
import { useContext, useState } from "react";
import { WindowWidthContext } from "../../../contexts/WindowWidthProvider";
import ProductCard from "../../ProductCard/ProductCard";
import PackCard from "../../PackCard/PackCard";
import Modal from '../../DetailsProductModal/ModalContent';

const ProductSwiper: React.FC<ProductSwiperProps> = ({ items, itemType }) => {
    const { productIds, isProductIds } = useProductIds();
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { windowWidth } = useContext(WindowWidthContext)
    const [showModal, setShowModal] = useState(false);


    const openModal = (product: any) => {
        setSelectedProduct(product);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    let screen = 4

    if (windowWidth < 1400 && windowWidth >= 1200) {
        screen = 4
    } else if (windowWidth < 1200 && windowWidth >= 992) {
        screen = 3
    }
    else if (windowWidth < 992 && windowWidth >= 768) {
        screen = 3
    } else if (windowWidth < 768 && windowWidth >= 578) {
        screen = 2
    } else if (windowWidth < 578) {
        screen = 1
    }



    return (
        <>
            <Swiper
                className="mySwiper"
                slidesPerView={screen}
                // loop={true}
                modules={[Autoplay, Pagination, Navigation]}
                pagination={{
                    clickable: true,
                }}
                // autoplay={false}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}

            >
                {items.map((item: any) => (
                    <SwiperSlide key={item.id}>
                        {itemType === 'product' ?
                            <ProductCard
                                key={item.id}
                                item={item}
                                productIdsObj={{ productIds, isProductIds }}
                                openModal={openModal}
                                bsCol="col-12" />
                            :
                            <PackCard key={item.id} item={item} />
                        }
                    </SwiperSlide>
                ))}
            </Swiper>
            {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />}
        </>
    );
};

export default ProductSwiper;