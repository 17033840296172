import { useEffect } from 'react';

type Callback = (event: MouseEvent | TouchEvent) => void;

const useClickOutside = (
    ref: React.RefObject<HTMLElement>,
    callback: Callback,
    blockRef: React.RefObject<HTMLElement> | null = null
) => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {

            if (blockRef && blockRef.current && blockRef.current.contains(event.target as Node)) {
                return;
            }
            callback(event);
        }
    };

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            handleClickOutside(event);
        };

        const handleDocumentTouch = (event: TouchEvent) => {
            handleClickOutside(event);
        };

        document.addEventListener('mousedown', handleDocumentClick);
        document.addEventListener('touchstart', handleDocumentTouch);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
            document.removeEventListener('touchstart', handleDocumentTouch);
        };
    }, [ref, callback, blockRef]);
};

export default useClickOutside;