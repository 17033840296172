import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import Footer from '../../components/Footer/Footer';
import { BsDot } from 'react-icons/bs';
import { Helmet } from 'react-helmet-async';

const TermsOfSale: React.FC = (props) => {

    return (
        <>
            <Helmet>
                <title>HBeauty - Conditions Générales De Vente</title>
            </Helmet>
            <TopHeader />
            <Navbar />
            <HeaderContainer
                className="fw-bold fs-1 mt-5 "
                title='Conditions Générales De Vente'
            />

            <div className="container">
                <div className="row d-flex justify-content-start align-items-center">
                    <div className="col-12 terms">
                        <p>
                            <p className='fs-18'>Vous êtes connectés sur le site www.hbeauty.ma de la Société <span className='clr-gold'>HBeauty</span> .</p>
                            <p className='fs-18'>Vous devez lire attentivement les conditions générales de vente qui vont suivre, proposées en langue française, en vue de la conclusion d’un contrat à distance régissant l’ensemble des transactions établies sur le catalogue Web de la Société Ste <span className='clr-gold'>HBeauty</span>.</p>
                            <p className='fs-18'>Toutes les ventes contractées sur le site www.hbeauty.ma sont réalisées par l’intermédiaire de la plate-forme CMI.</p>
                            <p className='fs-18'>Toute commande passée sur ce site suppose du client son acceptation inconditionnelle et irrévocable de ces conditions.</p>

                            <p className='fw-bold me-2 pt-2'>Article 1 : Champ d’application des Conditions Générales de Vente</p>

                            <p className='fs-18'>Les présentes Conditions Générales de Vente (CGV) s’appliquent à la vente des robots cuiseurs Ste <span className='clr-gold'>HBeauty</span> à travers le site internet www.hbeauty.ma.</p>
                            <p className='fs-18'>Le Client déclare avoir pris connaissance de l’ensemble des présentes Conditions Générales de Vente, et le cas échéant des Conditions Particulières de Vente liées à un produit ou à un service, et les accepter sans restriction ni réserve.</p>
                            <p className='fs-18'>Ste <span className='clr-gold'>HBeauty</span> se réserve la possibilité et le droit d’adapter ou de modifier les présentes conditions générales de vente à tout moment.</p>

                            <p className='fw-bold me-2 pt-2'>Article 2 : Prix</p>

                            <p className='fs-18'>Les prix des produits et articles mentionnés sur le site www.hbeauty.ma sont indiqués en dirham marocain TTC et pourraient être soumis à changement et ne sont donc pas contractuels.</p>
                            <p className='fs-18'>Les commandes sont effectuées sur le site internet www.hbeauty.ma. Le descriptif produit permet au Client, de prendre connaissance des caractéristiques nécessaires du produit qu'il désire commander.</p>
                            <p className='fs-18'>Cependant, Ste <span className='clr-gold'>HBeauty</span>  ne saurait être tenu responsable des erreurs éventuelles d'informations pouvant exister dans leur description.</p>

                            <p className='fw-bold me-2 pt-2'>Article 3 : Conditions de Passation de Commande</p>
                            <p className='fs-18'>Les informations contractuelles sont présentées en langue française et feront l'objet d'une confirmation au plus tard au moment de la validation de votre commande.</p>
                            <p className='fs-18'>La société <span className='clr-gold'>HBeauty</span> se réserve le droit de ne pas enregistrer un paiement, et de ne pas confirmer une commande pour quelque raison que ce soit, et plus particulièrement en cas de problème d'approvisionnement, ou en cas de difficulté concernant la commande reçue.</p>

                            <p className='fw-bold me-2 pt-2'>Article 4 : Validation de Commande</p>

                            <p className='fs-18'>Toute commande figurant sur le site Internet www.hbeauty.ma présume l'adhésion entière aux présentes Conditions Générales. Toute commande sera traitée suivant l´ordre dans lequel elle est effectivement réceptionnée par Ste <span className='clr-gold'>HBeauty</span> .</p>
                            <p className='fs-18'>L'ensemble des données fournies et la confirmation enregistrée vaudront preuve de la transaction.</p>
                            <p className='fs-18'>Vous déclarez en avoir parfaite connaissance.</p>
                            <p className='fs-18'>La confirmation de commande vaudra signature et acceptation des opérations effectuées. Un récapitulatif des informations de votre commande , vous sera communiqué via l'adresse e-mail de confirmation de votre commande.</p>

                            <p className='fw-bold me-2 pt-2'>Article 5: Informations Clients</p>
                            <p className='fs-18'>La responsabilité de www.hbeauty.ma ne saurait, en aucune manière, être recherchée dans l’éventualité où une erreur lors de la passation de la commande empêcherait ou retarderait la livraison.</p>
                            <p className='fs-18'>Le défaut de renseignement entraîne la non-validation de la commande. Toutes les informations communiquées par le Client lors de la passation de la commande (spécialement nom et adresse de livraison) engagent celui-ci.</p>

                            <p className='fw-bold me-2 pt-2'>Article 6: Disponibilité</p>

                            Les commandes seront traitées dans la limite de nos stocks disponibles.


                            <p className='fw-bold me-2 pt-2'>Article 7 : Contrôle</p>

                            <p className='fs-18'>Ste <span className='clr-gold'>HBeauty</span> se réserve le droit de contrôler la validité des paiements effectués sur le site.</p>
                            <p className='fs-18'>Ste <span className='clr-gold'>HBeauty</span> ainsi a le droit d´accepter la commande ou de la refuser en cas d’insolvabilité du Client ou dans le cas d’un défaut de paiement de la commande concernée ou d’une livraison antérieure, que l’achat ait été effectué en magasin ou sur site internet.</p>

                            <p className='fw-bold me-2 pt-2'>Article 8 : Modalités de livraison</p>
                            <p className='fs-18'>Les produits sont livrés à l'adresse de livraison et selon le délai indiqué au cours du processus de commande, en fonction des disponibilités et dans l'ordre d'arrivée des commandes et pourront être effectuées de manière globale ou partielle, si le Client commande plusieurs produits en même temps ceux-ci peuvent avoir des délais de livraison différents.</p>
                            <p className='fs-18'>Toute livraison non effectuée et due à une absence du Client au moment convenu pour la livraison ou non retrait des marchandises dans le délai imparti, pourra donner lieu au paiement par le client de frais supplémentaires, correspondant à une nouvelle livraison du produit initialement commandé et qui n'aurait pu être remis du fait du Client.</p>

                            <p className='fw-bold me-2 pt-2'>Article 9 : Retour de Marchandise Non Conforme</p>

                            <p className='fs-18'>Si les produits livrés ne sont pas conformes en nature ou en qualité, au bon de livraison, le Client doit, sous peine de nullité, formuler ses réclamations à Ste <span className='clr-gold'>HBeauty</span> , par fax ou e-mail dans les vingt-quatre heures (24) à partir de la date de livraison.</p>
                            <p className='fs-18'>La marchandise retournée à Ste <span className='clr-gold'>HBeauty</span>, devra être impérativement retournée dans son emballage d’origine faute de quoi, le produit ne pourra être accepté.</p>

                            <p className='fw-bold me-2 pt-2'>Article 10: Paiement</p>
                            <p className='fs-18'>Le paiement sur www.hbeauty.ma se fait via carte bancaire.</p>
                            <p className='fs-18'>La transaction est gérée par la plate-forme CMI.</p>

                            <p className='fw-bold me-2 pt-2'>Article 11 : Garantie et Retour de Produits</p>

                            <p className='fs-18'>Tous les produits bénéficient d'une garantie dont les conditions générales peuvent être consultées soit sur le bon de garantie joint au matériel, soit sur le site Internet www.hbeauty.ma  .</p>
                            <p className='fs-18'>En cas d’anomalies constatées lors de la livraison, le client peut retourner la marchandise pour traitement et réparation.</p>

                            <p className='fw-bold me-2 pt-2'>Article 12 : Clause de réserve de propriété</p>

                            <p className='fs-18'>Les produits demeurent à la propriété de la Société <span className='clr-gold'>HBeauty</span> jusqu’au paiement total de la somme due et encaissement intégral du prix affiché sur le site www.hbeauty.ma (frais et taxes incluses).</p>
                            <p className='fs-18'>Toutefois, les risques des marchandises retirées ou livrées, sont transférés au client, dès l’enlèvement ou de la réception de la commande par le client ou son représentant.</p>

                            <p className='fw-bold me-2 pt-2'>Article 13 : Droit de Rétractation</p>

                            <p className='fs-18'>Le Client dispose d'un délai de sept (7) jours à partir de la date de livraison, pour faire valoir son droit de rétractation et être intégralement remboursé.</p>
                            <p className='fs-18'>Les frais de renvoi des marchandises restant à la charge du client.</p>
                            <p className='fs-18'>Toutefois, seuls les produits retournés en parfait état de revente, intacts et dans leurs emballages d'origine (non ouverts et non utilisés) pourront faire l’objet d’un remboursement.</p>

                            <p className='fw-bold me-2 pt-2'>Article 14 : Service Après-Vente</p>

                            <p className='fs-18'>Pour bénéficier de la garantie, le client doit demander un numéro de retour au SAV Ste <span className='clr-gold'>HBeauty</span>.</p>
                            <p className='fs-18'>La facture d’achat ou de sa copie sera rigoureusement exigée.</p>

                            <p className='fs-18'>Certaines manipulations provoquent l’exclusion de l’application de la garantie.</p>
                            <p className='fs-18'>Sont exclus de l’application de la garantie, les défauts résultants des circonstances suivantes : foudre, dégâts des eaux, surtension d’origine électrique, surpression anormale de l’alimentation en fluide à des valeurs indiquées sur la notice d’utilisation (Evènements classés dans la catégorie catastrophes naturelles), les appareils brisés, bricolés ou démontés notamment ceux dont la rupture d’un témoin de démontage attestent qu’ils ont été démontés, la mauvaise manipulation ou le remplacement des pièces d’usure tels décrits dans la notice d’utilisation, ainsi que les articles démontés ou ayant fait l’objet d’une tentative de réparation maladroite ou inadaptée.</p>

                            <p className='fw-bold me-2 pt-2'>Article 15 : Confidentialité</p>

                            <p className='fs-18'>Ste <span className='clr-gold'>HBeauty</span> s´engage à ne pas communiquer les coordonnées du client ou celles relatives au paiement qui lui auront été transmises, sauf dans certains cas, en réponse à une injonction de justice, fraude sur internet, ou en magasin dans le cas d’une livraison pour faciliter une transaction ou au cas où la loi l’exige.</p>

                            <p className='fw-bold me-2 pt-2'>Article 16: Protection des personnes physiques à l’égard du traitement des données à caractère personnel (loi 09-08)</p>
                            <p className='fs-18'>Conformément à la loi N° 09-08 promulguée par le Dahir 1-09-15 du 18 février 2009, relative à la protection des personnes physiques à l'égard du traitement des données à caractère personnel, les données personnelles recueillies sur le site www.hbeauty.ma, sont déclarées à la Commission Nationale de Contrôle des Données à caractère Personnel sous le numéro …...</p>
                            <p className='fs-18'>Lorsqu’il est procédé à une collecte de données à caractère personnel, les données recueillies ne sont utilisées que pour mieux vous informer en vous transmettant les informations que vous souhaitez recevoir et éventuellement celles relatives aux offres de produits et services de Ste <span className='clr-gold'>HBeauty</span> ,</p>

                            <p className='fs-18'>Vous êtes informé de la finalité pour laquelle des informations vous concernant sont demandées, du caractère facultatif ou obligatoire des réponses à apporter et des destinataires des informations collectées.</p>
                            <p className='fs-18'>Cette information figure sur les formulaires électroniques de collecte remplis par vous.</p>
                            <p className='fs-18'>Conformément à la loi n° 09-08 promulguée par le Dahir 1-09-15 du 18 février 2009, relative à la protection des personnes physiques à l'égard du traitement des données à caractère personnel, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent, que vous pouvez exercer en vous adressant à info@hbeauty.ma.</p>
                            <p className='fs-18'>Vous pouvez également, pour des motifs légitimes, vous opposer à ce que les données qui vous concernent fassent l’objet d’un traitement.</p>
                            <p className='fs-18'>Votre courrier devra préciser l’objet de votre demande. Vous pouvez également refuser de participer à des enquêtes ou des sondages en écrivant à la même adresse.</p>
                            <p className='fs-18'>Responsable du traitement : Le traitement de réclamation se fera via le service client Ste <span className='clr-gold'>HBeauty</span> en contactant le numéro: +212661297515  ou par e-mail : info@hbeauty.ma.</p>
                            <p className='fw-bold me-2 pt-2'>Article 17 : Compétence juridictionnelle et droit applicable</p>

                            <p className='fs-18'>Les présentes conditions générales de vente sont régies par la loi Marocaine.</p>
                            <p className='fs-18'>Tout litige relatif à la conclusion, l'interprétation, l'exécution des présentes conditions générales sera soumis aux tribunaux de casablanca exclusivement compétents y compris en référé, nonobstant l'appel en garantie ou la pluralité de défendeurs.</p>



                        </p>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
};

export default TermsOfSale;
