import React, { createContext, useContext, useEffect, useState } from 'react';
import { apiUrl } from '../helpers/ApiRequest';
import { CategorieProps } from '../helpers/Props';
import useApiRequest from '@mbs-dev/api-request';


const CategoriesContext = createContext<{
    categories: CategorieProps[];
    isFetch: boolean
}>({
    categories: [],
    isFetch: true
});

export const useCategoriesContext = () => useContext(CategoriesContext);

export const CategoriesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [categories, setCategories] = useState<CategorieProps[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const { apiRequest } = useApiRequest()

    const fetchData = async () => {
        try {
            setIsLoading(true)
            const response = await apiRequest({
                route: `${apiUrl}/categories-with-parent`,
                method: 'GET'
            });

            if (response.status === 200) {
                setCategories(response.data)
                setIsLoading(false)
            }
        } catch (error) {
            //console.error(error);
        }
    };
    useEffect(() => {
        fetchData();
    }, [])

    return (
        <>
            <CategoriesContext.Provider value={{ categories, isFetch: isLoading }}>
                {children}
            </CategoriesContext.Provider>
        </>

    );
};
