import React, { useEffect, useRef } from "react";
import "./BackToTop.css";
import { MdExpandLess } from "react-icons/md";

const BackToTop: React.FC = () => {
    const scrollProgressRef = useRef<HTMLDivElement>(null);

    const calcScrollValue = () => {
        const scrollProgress = scrollProgressRef.current;
        const pos = document.documentElement.scrollTop;
        const calcHeight =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const scrollValue = Math.round((pos * 100) / calcHeight);

        if (scrollProgress) {
            scrollProgress.style.display = scrollValue > 5 ? "grid" : "none";
            scrollProgress.style.background = `conic-gradient(rgb(33 37 41 / 68%) ${scrollValue}%, rgb(255, 255, 255) ${scrollValue}%)`;
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", calcScrollValue);
        return () => {
            window.removeEventListener("scroll", calcScrollValue);
        };
    }, []);

    const handleScrollToTop = () => {
        document.documentElement.scrollTop = 0;
    };

    return (
        <div id="progress" ref={scrollProgressRef} onClick={handleScrollToTop}>
            <span id="progress-value">
                <MdExpandLess className="scroll-to-top-icon" />
            </span>
        </div>
    );
};

export {BackToTop};
