import React, { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

interface CheckoutGuardProps {
    children: ReactNode;
}

const CheckoutGuard: React.FC<CheckoutGuardProps> = ({ children }) => {
    const isguarded = localStorage.getItem('isguarded')

    if (!isguarded) {
        return <Navigate to="/my-cart" />;
    }

    return <>{children}</>;
};

export default CheckoutGuard;