import React, { lazy, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import { isEven } from '../../helpers/ApiRequest';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import TopHeader from '../../components/TopHeader/TopHeader';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/Footer/Footer';
import { useMarquesContext } from '../../contexts/MarquesContext';
import './DetailsMarques.css'

const DetailsMarques: React.FC = () => {

    const { marques } = useMarquesContext();
    const { windowWidth } = useContext(WindowWidthContext)


    return (

        <>
            <Helmet>
                <title>Nos Marques</title>
            </Helmet>
            <TopHeader />
            <Navbar />

            <HeaderContainer
                className="fw-bold mt-5 contact-title"
                title='Nos Marques'
            />



            <div className="container details-marques-container">
                <>
                    <div className="row details-marques-row d-flex-cente" >
                        {
                            marques?.map((item, index) => (

                                // <>
                                    <div key={index}  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 p-2 mb-4 d-flex-center details-marques-col-img">
                                        <div className="details-marques-content p-0">
                                            <div className="details-marques-image px-2">
                                                <Link to={`/boutique/marque/${item.slug}`}>
                                                    <img
                                                        src={`${process.env.REACT_APP_API_UPLOAD}/marque_images/${item.image}`}
                                                        alt={item.title}
                                                        className='details-marques-img mt-2' />
                                                </Link>
                                            </div>

                                            <Link to={`/boutique/marque/${item.slug}`} className="hb-link c-pointer details-marques-title d-flex-center">
                                                <h5 className='top-image-desc ff-3 fw-600 text-center c-pointer'>{item.title}</h5>
                                            </Link>
                                        </div>
                                    </div>

                                // {/* </> */}
                            ))
                        }

                    </div>

                    {/* <hr className='hr-tag' /> */}
                </>
            </div>
            <Footer />
        </>
    );
};

export default DetailsMarques;
