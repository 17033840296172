import React, { memo, useCallback, useEffect, useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { BiPlus } from 'react-icons/bi';
import { HiMinusSm } from 'react-icons/hi';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import { Link } from 'react-router-dom';
import useApiRequest from '@mbs-dev/api-request';
import { CartItemProps } from '../../helpers/Props';
import { useShoppingCartDataContext } from '../../contexts/ShoppingCartDataContext';
import { FrCustomeErrorNorify } from '../../helpers/Toastify';
import './ShoppingCartItem.css'
import { apiUrl } from '../../helpers/ApiRequest';

const ShoppingCartItem: React.FC<CartItemProps> = ({ product, quantity }) => {

  const {
    removeFromCart,
    incrementCartQuantity,
    decrementCartQuantity,
  } = useShoppingCart()

  const { products, packs } = useShoppingCartDataContext();
  const [discount, setDiscount] = useState(null);
  const [discountPrice, setDiscountPrice] = useState<number | null>(null);

  const { apiRequest } = useApiRequest();

  const mergedArray = [...products, ...packs];
  const item = mergedArray.find(i => i.itemType === 'pack' ? i.uniqId === product : i.id === product)

  const fetchPromotionData = useCallback(async () => {
    try {

      if (item?.itemType === 'product' && item?.id) {
        const response = await apiRequest({
          route: `${apiUrl}/promotion-discount/product_id/${item.id}`,
          method: 'GET'
        });

        if (response.status === 200) {
          // setDiscount(response.data.discount);

          response.data.discount !== null ? setDiscount(response.data.discount) : setDiscountPrice(response.data.price);
          // response.data.price !== null ? setDiscountPrice(response.data.price) : undefined;
        } else {
          FrCustomeErrorNorify()
        }
      }

    } catch (error) {

    }
  }, [item?.id]);


  useEffect(() => {
    fetchPromotionData();
  }, [item?.id])

  if (item == null) return null

  // let productPrice = discount !== null ? (item.tarif_regulier - (item.tarif_regulier * (discount / 100))) : item.tarif_regulier;

  let productPrice = discount !== null ?
    item.tarif_regulier - (item.tarif_regulier * (discount / 100))
    : discountPrice !== null ? discountPrice
      : item.tarif_regulier;


  return (
    <>
      <div className="shopping-card-item d-flex justify-content-betdween align-items-center">
        <div className="container">
          <div className="row justify-content-center align-items-center shopping-card-item-content">
            <div className="col-lg-2 col-md-2 col-sm-5 col-5 d-flex align-items-center shopping-card-item-image">
              <Link to={`${item.itemType === 'pack' ? `/pack/${item.slug}` : `/produit/${item.slug}`}`} className='d-inline-flex h-100'>
                <img src={`${process.env.REACT_APP_API_UPLOAD}/${item.itemType === 'pack' ? 'pack' : 'product'}_images/${item.image}`} alt={item.title} className="rounded shopping-card-item-img" />
              </Link>
            </div>

            <div className="col-lg-5 col-md-5 col-sm-7 col-7 d-flex align-items-center">
              <div className="shopping-card-item-details">
                <div className="shopping-card-item-title">{item.title}</div>
                <div className="shopping-card-item-prix clr-dark">{item.itemType === 'pack' ? item.tarif_regulier.toFixed(2) : productPrice.toFixed(2)} DH</div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex align-items-center">
              <div className="shopping-card-item-quantity align-items-center text-center">
                <span className="align-items-center fs-4" onClick={quantity > 1 ? () => decrementCartQuantity(item.itemType === 'pack' ? item.uniqId : item.id) : undefined}>
                  <HiMinusSm className='shopping-card-item-quantity-icon' />
                </span>
                <span className="align-items-center px-2 shopping-card-item-quantity-value">{quantity}</span>
                <span className="align-items-center fs-4" onClick={() => incrementCartQuantity(item.itemType === 'pack' ? item.uniqId : item.id, item.itemType === 'pack' ? item.tarif_regulier : productPrice)}>
                  <BiPlus className='shopping-card-item-quantity-icon' />
                </span>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-4 col-4 d-flex align-items-center">
              <div className="shopping-card-item-total align-items-center">
                <span className="align-items-center px-">{item.itemType === 'pack' ? item.tarif_regulier.toFixed(2) : (productPrice * quantity).toFixed(2)} DH</span>
              </div>
            </div>

            <div className="col-lg-1 col-md-1 col-sm-2 col-2 d-flex align-items-center">
              <div className="trash-icon">
                <BsFillTrashFill className="trash" onClick={(() => removeFromCart(item.itemType === 'pack' ? item.uniqId : item.id))} />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MemoizedShoppingCartItem = memo(ShoppingCartItem);

export { MemoizedShoppingCartItem as ShoppingCartItem };