import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserDashboard } from '../UserDashboard/UserDashboard';
import { BsChatRightDots, BsPlusCircleDotted, BsDot } from 'react-icons/bs'
import { CiEdit } from 'react-icons/ci'
import { apiUrl } from '../../../../helpers/ApiRequest';
import { AuthUserProps, UserAdresseProps } from '../../../../helpers/Props';
import LoadingMore from '../../../../components/Loading/LoadingMore';
import location from '../../../../../assets/images/account/location.png'
import { Helmet } from 'react-helmet-async';
import './EditAddressStyle.css'
import { FrCustomeErrorNorify } from '../../../../helpers/Toastify';
import AnimatedLoading from '../../../../components/Loading/AnimatedLoading';
import useApiRequest from '@mbs-dev/api-request';

const Content: React.FC = React.memo(() => {

    const { apiRequest } = useApiRequest();
    const [facturationAdresse, setFacturationAdresse] = useState<UserAdresseProps[]>([]);
    const [livraisonAdresse, setLivraisonAdresse] = useState<UserAdresseProps[]>([]);
    const [user, setUser] = useState<AuthUserProps | null>(null);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        const storedUser = localStorage.getItem('hb_user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);


    const fetchUserAdresse = async () => {
        try {
            setIsLoading(true)
            if (user?.id) {
                const response = await apiRequest({
                    route: `${apiUrl}/user_adresses/user_id/${user?.id}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`
                });

                if (response.status === 200) {
                    setLivraisonAdresse(response.data.livraisonAdresse);
                    setFacturationAdresse(response.data.facturationAdresse);
                    setIsLoading(false)
                } else {
                    FrCustomeErrorNorify()
                    setIsLoading(false)
                }
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    };

    useEffect(() => {
        fetchUserAdresse();
    }, [user?.id])

    return (
        <>
            <Helmet>
                <title>Adresses</title>
            </Helmet>
            <div className="col-11 col-lg-7 col-md-7 col-xl-8 col-xxl-9 ms-4">
                {
                    isLoading ?
                        <AnimatedLoading label='Des Adresses' className='col-12' />
                        :
                        <div className="account-order ms-4 hb-me-3">
                            <div className="order-message d-flex justify-content-center align-items-center mb-5">
                                <span className='account-notif'><BsChatRightDots className='order-message-icon' /> Les adresses suivantes seront utilisées par défaut sur la page de commande.</span>
                            </div>
                            <div className={`account-adresses order-content ${livraisonAdresse.length === 0 || facturationAdresse.length === 0 ? 'd-flex justify-content-center align-items-center' : ''}`}>
                                <div className="conatiner text-center mt-2">
                                    {livraisonAdresse.length > 0 || facturationAdresse.length > 0 || true
                                        ?
                                        <div className="row">
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-12 mb-4 text-left">
                                                <div className='nav-link'>
                                                    <h5 className='text-center'>Adresse de facturation
                                                        {
                                                            facturationAdresse[0]?.adresseType === 'facturation' ?
                                                                <Link to={`facturation/${facturationAdresse[0]?.id}`}>
                                                                    <span className='account-adresse-edit-content'>
                                                                        <CiEdit className='account-adresse-icon-edit' />
                                                                    </span>
                                                                </Link>
                                                                :
                                                                <Link to='facturation'><BsPlusCircleDotted className='account-adresse-icon' /></Link>
                                                        }
                                                    </h5>
                                                </div>

                                                {facturationAdresse[0]?.adresseType === 'facturation'
                                                    ?

                                                    <div className="containe">
                                                        <div className="row text-start mt-5">

                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Nom Prenom :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.nom} ${facturationAdresse[0]?.prenom}`}
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Telephone :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.telephone}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Adresse Email :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.email}`}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>N° et Nom de rue :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.rueInfo}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Ville :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.ville}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Région :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.region}`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Code postal :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {`${facturationAdresse[0]?.codepostal}`}
                                                                    </div>
                                                                </div>
                                                            </div> */}

                                                            <div className="col-12 mb-1">
                                                                <div className="row">
                                                                    <div className="col-7">
                                                                        <BsDot className='fs-3 adress-details-icon' />
                                                                        <span className='adress-details-text'>Nom de l'entreprise :</span>
                                                                    </div>
                                                                    <div className="adress-details-value col-5">
                                                                        {` ${facturationAdresse[0]?.entreprise ? facturationAdresse[0]?.entreprise : 'Non ajouté'}`}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    :

                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-12 d-flex justify-content-center align-items-center">
                                                                    <img className='mt-5 nolocation-img ' src={location} alt="location" />
                                                                </div>
                                                                <div className="col-12">
                                                                    <h5 className='adress-details-value mt-5 text-center clr-blue'>
                                                                        Adresse de facturation Non ajouté
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                }
                                            </div>
                                            <div className="col-xl-6 col-lg-12 col-md-12 col-12 text-left">
                                                <h5 className='text-center'>Adresse de livraison
                                                    {
                                                        livraisonAdresse[0]?.adresseType === 'livraison' ?
                                                            <Link to={`livraison/${livraisonAdresse[0].id}`}>
                                                                <span className='edit-livraison-icon account-adresse-edit-content'>
                                                                    <CiEdit className='account-adresse-icon-edit' />
                                                                </span>
                                                            </Link>
                                                            :
                                                            <Link to='livraison'><BsPlusCircleDotted className='livraison-icon account-adresse-icon' /></Link>
                                                    }
                                                </h5>

                                                {
                                                    livraisonAdresse[0]?.adresseType === 'livraison'
                                                        ?
                                                        <div className="containe">
                                                            <div className="row text-start mt-5">

                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Nom Prenom :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.nom} ${livraisonAdresse[0]?.prenom}`}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Telephone :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.telephone}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Adresse Email :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.email}`}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>N° et Nom de rue :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.rueInfo}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Ville :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.ville}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Région :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.region}`}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Code postal :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {`${livraisonAdresse[0]?.codepostal}`}
                                                                        </div>
                                                                    </div>
                                                                </div> */}

                                                                <div className="col-12 mb-1">
                                                                    <div className="row">
                                                                        <div className="col-7">
                                                                            <BsDot className='fs-3 adress-details-icon' />
                                                                            <span className='adress-details-text'>Nom de l'entreprise :</span>
                                                                        </div>
                                                                        <div className="adress-details-value col-5">
                                                                            {` ${livraisonAdresse[0]?.entreprise ? livraisonAdresse[0]?.entreprise : 'Non ajouté'}`}
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='d-flex justify-content-center align-items-center'>
                                                            <div className="container">
                                                                <div className="row">
                                                                    <div className="col-12 d-flex justify-content-center align-items-center">
                                                                        <img className='mt-5 nolocation-img ' src={location} alt="location" />
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <h5 className='adress-details-value mt-5 text-center clr-blue'>
                                                                            Adresse de livraison Non ajouté
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                }

                                                {/* <div className="container">
                                        <div className="row">

                                        </div>
                                    </div> */}
                                            </div>
                                        </div>
                                        :
                                        <>
                                            < LoadingMore />
                                            <p className='adress-details-value position-absolute' style={{ margin: '0 0 0 -4rem' }}>
                                                Chargement des adresses...
                                            </p>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                }
            </div>
        </>
    );
});


const EditAddress: React.FC = () => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export { EditAddress };