import { FC } from 'react';
import { useMarquesContext } from '../../contexts/MarquesContext';
import MarqueSwiper from '../Carousel/MarqueSwiper/MarqueSwiper';
import './MarqueStyle.css'

const Marques: FC = () => {
  const { marques } = useMarquesContext();

  return (
    <>
      {
        marques.length > 0 ?
          <div className="marques">
            <div className="product-containe container mx-">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-md-12 col-lg-12 text-center justify-content-center mb-3">
                  <div className="d-inline">
                    <h2 className="fw-bold fs-2">Nos Marques</h2>
                    <p>Découvrez les produits de nos marques partenaires</p>
                  </div>
                </div>
                <div className="col-md-12 col-lg-12 col-11 marque-slider">
                  <MarqueSwiper marques={marques} />
                </div>
              </div>
            </div>
          </div>
          :
          null
      }
    </>
  );
};

export default Marques;