import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home/Home';
import { ShoppingCart } from '../pages/ShoppingCart/ShoppingCart';
import Login from '../pages/Auth/Login';
import Register from '../pages/Auth/Register';
import Contact from '../pages/Contact/Contact';
import { EditAccount } from '../pages/account/pages/UserEditAccount/EditAccount';
import { DashContent } from '../pages/account/pages/UserDashboard/DashContent';
import { UserOrders } from '../pages/account/pages/Orders/UserOrders';
import { EditAddress } from '../pages/account/pages/UserAdresse/EditAddress';
import { CodePromo } from '../pages/account/pages/CodePromo/CodePromo';
import AdresseFacturation from '../pages/account/pages/UserAdresse/pages/AdresseFacturation';
import AdresseLivraison from '../pages/account/pages/UserAdresse/pages/AdresseLivraison';
import TermsOfUse from '../pages/Terms/TermsOfUse';
import TermsOfSale from '../pages/Terms/TermsOfSale';
import { Checkout } from '../pages/Checkout/Checkout';
import { UserOrderDetails } from '../pages/account/pages/Orders/UserOrderDetails';

import LazyBoutique from './LazyBoutique';
import LazyPackBoutique from './LazyPackBoutique';
import ForgotPassword from '../pages/Auth/ForgotPassword';
import ResetPassword from '../pages/Auth/ResetPassword';
import { UserReviews } from '../pages/account/pages/UserReviews/UserReviews';
import Error404 from '../pages/Errors/Error404';
import DetailsCategories from '../pages/DetailsCategories/DetailsCategories';
import DetailsMarques from '../pages/DetailsMarques/DetailsMarques';
import CmiOkPage from '../pages/Payment/CmiOkPage';
import CmiFailPage from '../pages/Payment/CmiFailPage';
import CheckoutGuard from './CheckoutGuard';
import ProductDetails from '../pages/ProductDetails/ProductDetails';
import PackDetails from '../pages/PackDetails/PackDetails';

const Routing: React.FC = () => {

  return (
    <Router basename="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Error404 />} />
        <Route path="/my-cart" element={<ShoppingCart />} />
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset_password/:token" element={<ResetPassword />} />
        <Route path="/register" element={<Register />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/boutique" element={<LazyBoutique />} />
        <Route path="/boutique/packs" element={<LazyPackBoutique />} />
        <Route path="/boutique/categorie/:categorieId" element={<LazyBoutique />} />
        <Route path="/boutique/categorie/:categorieId/:categorieId" element={<LazyBoutique />} />
        <Route path="/boutique/marque/:marqueId" element={<LazyBoutique />} />
        <Route path="sort/:sortType?" element={<LazyBoutique />} />
        <Route path="/produit/:slug" element={<ProductDetails />} />
        <Route path="/pack/:slug" element={<PackDetails />} />
        <Route path="/my-account/edit-account" element={<EditAccount />} />
        <Route path="/my-account" element={<DashContent />} />
        <Route path="/my-account/orders" element={<UserOrders />} />
        <Route path="/my-account/orders/order-details/:id" element={<UserOrderDetails />} />
        <Route path="/my-account/edit-address" element={<EditAddress />} />
        <Route path="/my-account/code-promo" element={<CodePromo />} />
        <Route path="/my-account/reviews" element={<UserReviews />} />
        <Route path="/my-account/edit-address/facturation" element={<AdresseFacturation />} />
        <Route path="/my-account/edit-address/facturation/:adresseId" element={<AdresseFacturation />} />
        <Route path="/my-account/edit-address/livraison" element={<AdresseLivraison />} />
        <Route path="/my-account/edit-address/livraison/:adresseId" element={<AdresseLivraison />} />
        <Route path="/conditions-generales-dutilisation" element={<TermsOfUse />} />
        <Route path="/conditions-generales-de-vente" element={<TermsOfSale />} />
        {/* <Route path="/checkout" element={<Checkout />} /> */}
        <Route
          path="/checkout"
          element={
            <CheckoutGuard>
              <Checkout />
            </CheckoutGuard>
          }
        />
        <Route path="/nos-categories" element={<DetailsCategories />} />
        <Route path="/nos-marques" element={<DetailsMarques />} />
        <Route path="/payments/cmi/ok" element={<CmiOkPage />} />
        <Route path="/payments/cmi/fail" element={<CmiFailPage />} />
      </Routes>
    </Router>
  );
};

export { Routing };