import React, { useState } from 'react';
import { UserDashboard } from '../UserDashboard/UserDashboard';
import {BsChatRightDots} from 'react-icons/bs'

const Content: React.FC = () => {

    return (
        <>
            <div className="col-12 col-lg-7 col-md-7 col-xl-8 col-xxl-9 hb-ms-4 newold">
                <div className="account-order ms-4 hb-me-3">
                    <div className="order-content">

                    </div>

                    <div className="order-message d-flex justify-content-center align-items-center">
                            <span className='account-notif'><BsChatRightDots className='order-message-icon'/> Désolé, aucun code promo actuellement disponible.</span>
                    </div>
                </div>


            </div>
        </>
    );
};


const CodePromo: React.FC = () => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export { CodePromo };
