import React, { memo, useCallback, useEffect, useState } from 'react';
import { BsFillTrashFill } from 'react-icons/bs';
import { GrFormClose } from 'react-icons/gr';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import useApiRequest from '@mbs-dev/api-request';
import { CartItemProps } from '../../helpers/Props';
import { useShoppingCartDataContext } from '../../contexts/ShoppingCartDataContext';
import './SubShoppingCartStyle.css'
import { apiUrl } from '../../helpers/ApiRequest';


const SubShoppingCart: React.FC<CartItemProps> = ({ product, quantity }) => {

  const { removeFromCart, cartItems } = useShoppingCart()
  const { apiRequest } = useApiRequest();
  const [discount, setDiscount] = useState(null);
  const [discountPrice, setDiscountPrice] = useState<number | null>(null);

  const { products, packs } = useShoppingCartDataContext();

  const mergedArray = [...products, ...packs];

  const item = mergedArray.find(i => i.itemType === 'pack' ? i.uniqId === product : i.id === product)


  const fetchData = useCallback(async () => {
    try {

      if (item?.itemType === 'product' && item?.id) {
        const response = await apiRequest({
          route: `${apiUrl}/promotion-discount/product_id/${item.id}`,
          method: 'GET'
        });

        response.data.discount !== null ? setDiscount(response.data.discount) : setDiscountPrice(response.data.price);
        // setDiscount(response.data.discount)
        // response.data.discount !== null ?? setDiscount(response.data.discount);
        // response.data.price !== null ?? setDiscountPrice(response.data.price);
        
      }

    } catch (error) {

    }
  }, [item?.id]);


  useEffect(() => {
    fetchData();
  }, [item?.id])

  if (item == null) return null

  // let productPrice = discount !== null ? (item.tarif_regulier - (item.tarif_regulier * (discount / 100))) : item.tarif_regulier;

  let productPrice = discount !== null ?
    item.tarif_regulier - (item.tarif_regulier * (discount / 100))
    : discountPrice !== null ? discountPrice
      : item.tarif_regulier;


  return (
    <>
      <div className="subshopping-card-item d-flex align-items-center">
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-md-3 col-lg-3 col-sm-3 col-3 h-100">
              <img src={`${process.env.REACT_APP_API_UPLOAD}/${item.itemType === 'pack' ? 'pack' : 'product'}_images/${item.image}`} alt={item.title} className="subshopping-card-item-img" />
            </div>
            <div className="col-md-8 col-lg-8 col-sm-8 col-8">
              <div className="subshopping-card-item-details">
                <div className="subshopping-card-item-title align-items-center">
                  <span className='subshopping-card-item-quantity clr-orange'>{quantity}</span> <GrFormClose className='subshopping-card-close-icon' /> {item.title}
                </div>
                <div className="subshopping-card-item-prix">{item.itemType === 'pack' ? item.tarif_regulier.toFixed(2) : (productPrice * quantity).toFixed(2)} DH</div>
              </div>
            </div>
            <div className="col-md-1 col-lg-1 col-sm-1 col-1 d-flex align-items-center">
              <div className="trash-icon">
                <BsFillTrashFill className="trash" onClick={(() => removeFromCart(item.itemType === 'pack' ? item.uniqId : item.id))} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const MemoizedSubShoppingCart = memo(SubShoppingCart);

export { MemoizedSubShoppingCart as SubShoppingCart };