import axios from "axios";
import { InfoNotify } from "./Toastify";


const API_ROUTE = process.env.REACT_APP_API_URL;


interface IApiRequest {
    route: string;
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH" | "IGET";
    requiresAuth?: boolean;
    body?: any;
    formData?: boolean;
    params?: {
        startIndex?: number,
        stopIndex?: number,
    }
}


export const ApiRequest = async ({
    route,
    method,
    requiresAuth = false,
    body,
    formData,
    params = {
        startIndex: 1,
        stopIndex: 1,
    }
}: IApiRequest) => {
    //  try {
    //     const headers: any = requiresAuth
    //       ? {
    //           Authorization: `Bearer ${getLocalUser().token}`,
    //         }
    //       : null;
    //     let response: any;

    //     const r = `${API_ROUTE}/${route}`;
    //     console.log(r);

    //     if (method === "POST") {
    //       response = await axios.post(r, body, {
    //         headers,
    //       });
    //     } else if (method === "GET") {
    //       response = await axios.get(r, {
    //         headers,
    //       });
    //     } else if (method === "DELETE") {
    //       response = await axios.delete(r, {
    //         headers,
    //       });
    //     } else if (method === "PUT") {
    //       response = await axios.put(r, body, {
    //         headers,
    //       });
    //     }

    //     return response.data;
    //   } catch (error) {
    //     throw error;
    //   } 
};

const isFile = (input: any) => "File" in window && input instanceof File;
const isFileList = (input: any) =>
    "File" in window && input instanceof FileList;

function useApiRequest() {

    const ApiRequest = async ({
        route,
        method,
        requiresAuth = false,
        body,
        formData,
        params = {
            startIndex: 1,
            stopIndex: 1,
        }
    }: IApiRequest) => {

        let dataToSend: any = body;

        try {

            let response: any;

            const r = `${API_ROUTE}/${route}`;
            const getRoute = `${API_ROUTE}/${route}?page=${params.startIndex}`;
            // console.log(r);

            if (method === "POST") {
                response = await axios.post(r, dataToSend, {

                });
            } else if (method === "GET") {
                response = await axios.get(r, {

                });

            } else if (method === "IGET") {
                response = await axios.get(getRoute, {

                });

            } else if (method === "DELETE") {
                response = await axios.delete(r, {
                });
            } else if (method === "PUT") {
                response = await axios.put(r, dataToSend, {

                });
            } else if (method === "PATCH") {
                response = await axios.patch(r, dataToSend, {

                });
            }

            return response;
        } catch (error) {
            throw error;
        }
    };

    return ApiRequest;
}


// --------------------------
// -    Helper Functions    -
// --------------------------

export function createFormData(data: any, files?: any) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
        formData.append(key, data[key]);
    });
    if (files) {
        files.map((file: any) => {
            formData.append("images[]", file)
        })
    }

    return formData;
}

const encodeHtmlAssociations: { [key: string]: string } = {
    ["<"]: "@lt",
    [">"]: "@gt",
}
const decodeHtmlAssociations: { [key: string]: string } = {
    ["@lt"]: "<",
    ["@gt"]: ">",
}
export function encodeHtmlTags(html: string): string {
    Object.keys(encodeHtmlAssociations).forEach((key: string) => {
        html = html.replace(new RegExp(key, "g"), encodeHtmlAssociations[key]);
    });
    return html
}

export function decodeHtmlTags(html: any): any {
    Object.keys(decodeHtmlAssociations).forEach((key: string) => {
        html = html.replace(new RegExp(key, "g"), decodeHtmlAssociations[key]);
    });
    return html
}


export const htmlDecode = (input: string): string => {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent || '';
};



// export const getDate = (date: any) => {
//     return moment(date).format("MM/DD/YYYY");
// };

export const getMoneyAmount = (amount: number) => {
    return `${amount} EUR`;
};

// export const showToast = (
//   message: string | string[],
//   type: "info" | "success" | "warning" | "error" | "default"
// ) => {
//   toast(message, {
//     type,
//     hideProgressBar: true,
//   });
// };

export const getUserPhoto = (photo: any) => {
    return photo
        ? `${process.env.NEXT_PUBLIC_STORAGE_ROUTE}/${photo}`
        : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";
};

export const getRangeYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];

    let startYear = currentYear - 50;

    while (startYear <= currentYear + 50) {
        years.push(startYear++);
    }

    return years;
};


export function UFormatDate(dateString: string | Date): string | Date {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${year}-${month}-${day}`;
}

export function FormatDate(dateString: string | Date): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
}


export function StringToDate(dateString: string): Date {
    return new Date(dateString);
}

export function getProductPrice(product: { tarif_promo: any; tarif_regulier: any; date_de_debut_de_promo: any; date_de_fin_de_promo: any }) {
    // if (StringToDate(product.date_de_fin_de_promo) > new Date()){
    if (StringToDate(product.date_de_debut_de_promo) <= new Date() && StringToDate(product.date_de_fin_de_promo) > new Date()) {
        return product.tarif_promo !== null ? product.tarif_promo : product.tarif_regulier;
    }
    else {
        return product.tarif_regulier;

    }

}


export function XformatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());

    return `${day}-${month}-${year}`;
}

export function formatDate(dateWithTimezone: string | any) {
    const dateWithoutTime = dateWithTimezone.slice(0, 10);
    const [year, month, day] = dateWithoutTime.split('-');
    return `${day}-${month}-${year}`;
}

export function DBFormatDate(date: Date | string | any) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}


export function logOut() {
    localStorage.removeItem('hb_user_token');
    localStorage.removeItem('hb_user');
}

export const referenceGenetorr = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = parseInt(String(currentDate.getDate()), 10);
    // const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const randomNumber = Math.floor(Math.random() * 99) + 1;

    const reference = `${randomNumber}${seconds}${day}${hours}${minutes}`;
    return reference;
};

export const referenceGenetor = () => {
    const currentDate = new Date();
    const year = String(currentDate.getFullYear()).slice(-2); // Extract the last two digits of the year
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const randomNumber = "000"; // You can change this if you want a random part

    const reference = `${year}${month}${randomNumber}`;
    return reference;
};

export const handleImageLinkDrage = (e: any) => {
    e.preventDefault();
};

export function formatPrice(price: string): string {
    const numericPrice = parseFloat(price);

    if (isNaN(numericPrice)) {
        return price;
    }

    return numericPrice.toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).replace(',', ' ');
}

export function formatDatex(inputDate: string): string {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear());
    return `${day}-${month}-${year}`;
}

interface TruncateTextProps {
    text: string;
    maxLength: number;
}
export function TruncateText({ text, maxLength }: TruncateTextProps) {
    if (text.length <= maxLength) {
        return <span>{text}</span>;
    }

    const truncatedText = text.slice(0, maxLength) + '...';

    return <span>{truncatedText}</span>;
}

export function isEven(number: number): boolean {
    return number % 2 === 0;
}

export const pendingGoogle = () => {
    InfoNotify('La connexion avec Google est en cours de maintenance.')
};

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname


export const apiUrl = process.env.REACT_APP_API_URL
export const uploadUrl = process.env.REACT_APP_API_UPLOAD
export const productImgUrl = `${uploadUrl}/product_images`
export const PMultiImgUrl = `${uploadUrl}/product_multi_images`
export const variantImgUrl = `${uploadUrl}/variant_images`
export const variantIconUrl = `${uploadUrl}/variant_icons`
export const VMultiImgUrl = `${uploadUrl}/variant_multi_images`
export const packImgUrl = `${uploadUrl}/pack_images`
export const marqueLogoUrl = `${uploadUrl}/marque_logos`
export const marqueImgUrl = `${uploadUrl}/marque_images`
export const categorieImgUrl = `${uploadUrl}/category_images`
export const sliderImgUrl = `${uploadUrl}/slider_images`
export const specialOffersImgUrl = `${uploadUrl}/offer_images`
export const blogImgUrl = `${uploadUrl}/blog_images`
export const blogMultiImgUrl = `${uploadUrl}/blog_multi_images`
export const blogPostImgUrl = `${uploadUrl}/blog_post_images`











// -----------------------
async function fetchImage(imageUrl: string): Promise<Blob | null> {
    try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
            throw new Error(`Failed to fetch image: ${response.statusText}`);
        }
        return await response.blob();
    } catch (error) {
        console.error('Error fetching image:', error);
        return null;
    }
}

export async function convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });
}

export const handleImageForPdf = async (imageUrl: string): Promise<string | null> => {
    const imageBlob = await fetchImage(imageUrl);
    if (!imageBlob) {
        return null;
    }
    const base64 = await convertBlobToBase64(imageBlob);
    // Now you have the base64 string, use it in your PDF library
    return base64;
}