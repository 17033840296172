import React, { createContext, useContext, useState, useEffect } from 'react';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { PackProps } from '../helpers/Props';
import { DBFormatDate, apiUrl } from '../helpers/ApiRequest';
import useApiRequest from '@mbs-dev/api-request';

const PacksContext = createContext<{
    packs: PackProps[];
    fetchNextPage?: () => void;
    isFetchingNextPage?: boolean;
    minPrice: number;
    maxPrice: number;
    totalPacks: number;
    reactQueryLoadingData: boolean;
}>({
    packs: [],
    fetchNextPage: () => null,
    isFetchingNextPage: false,
    minPrice: 0,
    maxPrice: 0,
    totalPacks: 0,
    reactQueryLoadingData: false,
});

export const usePacksContext = () => useContext(PacksContext);

export const PacksProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [totalPacks, setTotalPacks] = useState(0);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(0);
    const { apiRequest } = useApiRequest()
    const today = DBFormatDate(new Date());

    const {
        data,
        isLoading: reactQueryLoadingData,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery<PackProps[], Error>(
        'packs',
        async ({ pageParam = 1 }) => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/packs.json`, {
                params: {
                    page: pageParam,
                    itemsPerPage: 10,
                    'dateDebut[before]': today,
                    'dateFin[strictly_after]': today
                }
            });
            // const response = await axios.get(`${process.env.REACT_APP_API_URL}/active-pack?page=${pageParam}&itemsPerPage=8`);
            return response.data;
        },
        {
            getNextPageParam: (lastPage, pages) => pages.length + 1,
        }
    );

    const getTotalPacks = async () => {
        try {
            const response = await apiRequest({
                route: `${apiUrl}/total-packs`,
                method: 'GET'
            });

            setTotalPacks(response.data.total_packs.totalPacks);
            setMinPrice(response.data.total_packs.minPrice);
            setMaxPrice(response.data.total_packs.maxPrice);

        } catch (error) {
            //console.error(error);
        }
    };
    useEffect(() => {
        getTotalPacks();
    }, [])

    const packs = data?.pages.flat() || [];


    return (
        <PacksContext.Provider value={{
            packs,
            fetchNextPage,
            isFetchingNextPage,
            minPrice: Number(minPrice),
            maxPrice: Number(maxPrice),
            totalPacks: totalPacks,
            reactQueryLoadingData: reactQueryLoadingData
        }}>
            {children}
        </PacksContext.Provider>
    );
};
