import React from 'react';

const LoadingMore: React.FC = () => {
  return (
    <div className="loading-more mt-5 mb-4">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
    </div>
  );
}

export default LoadingMore;