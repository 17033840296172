import ReactLoading from "react-loading";
import './loadingDataStyle.css'

const LoadingData: React.FC = () => {

    return (
        <>
            <div className="animation-bar-1">
                <span></span>
            </div>
        </>
    );
};

export default LoadingData;