import React from 'react';
// import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrateRoot } from 'react-dom/client'; // Import hydrateRoot
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

const rootElement = document.getElementById('root') as HTMLElement;

hydrateRoot(
  rootElement,
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();