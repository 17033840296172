import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import Footer from '../../components/Footer/Footer';
import './TermsStyle.css'
import { BsDot } from 'react-icons/bs';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const TermsOfUse: React.FC = (props) => {

    return (
        <>
            <Helmet>
                <title>HBeauty - Conditions Générales D’utilisation</title>
            </Helmet>
            <TopHeader />
            <Navbar />
            <HeaderContainer
                className="fw-bold fs-1 mt-5"
                title='Conditions Générales D’utilisation'
            />

            <div className="container ">
                <div className="row d-flex justify-content-start align-items-center">
                    <div className="col-12 terms">
                        <p>
                            <p className='fs-18'>
                                <span className='fw-bold me-2'>Conditions générales d’utilisation du site</span>
                                <span className='clr-gold'>HBeauty</span>
                            </p>


                            <p className='fs-18'>Les présentes Conditions Générales s’appliquent à l’ensemble du contenu et des services accessibles depuis le site www.hbeauty.ma</p>

                            <p className='fs-18'><span className='clr-gold'>HBeauty</span> a établi ces conditions dans le but de protéger la communauté qui accède à son site et les droits de chacun. En vous connectant au site, vous acceptez explicitement ces conditions générales d’utilisation.</p>

                            <p className='fs-18'>Pour toute question ou suggestion, veuillez nous contacter à l’adresse électronique suivante : info@hbeauty.ma</p>

                            <p className='fs-18'><span className='clr-gold'>HBeauty</span> remercie toutes les personnes qui visitent le Site et y contribuent par leurs commentaires, articles et/ou vidéos dans le respect des présentes Conditions générales d’utilisation.</p>

                            <p className='fw-bold me-2'>Mentions légales</p>

                            <p className='fs-18'>Ces champs sont régis par les dispositions de la loi 09-08 relative à la protection des données personnelles, nous portons à votre connaissance les éléments suivants :</p>

                            <p className='ps-4'><BsDot className='fs-3 adress-details-icon' /> Forme juridique : SARL</p>

                            <p className='ps-4'><BsDot className='fs-3 adress-details-icon' /> Nom de la société mère : SociéHBEAUTY</p>

                            <p className='ps-4'><BsDot className='fs-3 adress-details-icon' /> Adresse électronique : info@hbeauty.ma</p>

                            <p className='fw-bold me-2'>Acceptation des conditions d’utilisation :</p>

                            <p className='fs-18'>
                                <span className='clr-gold'>HBeauty</span> met à disposition de l’utilisateur sur son site web www.hbeauty.ma  des informations, documents, et autres services, soumis aux termes et conditions présentés ci-dessous.

                                Ces conditions peuvent être modifiées à tout moment, sans aucun avis préalable. <span className='clr-gold'>HBeauty</span> se réserve ainsi le droit de modifier les conditions d’utilisation quand il le désire, à sa seule discrétion. Votre utilisation du site sera donc automatiquement soumise à la version la plus récente des conditions d’utilisation affichées sur le site.

                                Le non-respect de l’une ou l’autre des conditions d’utilisation met automatiquement fin à votre autorisation d’utiliser ce site.
                            </p>

                            <p className='fw-bold me-2'>Politique de confidentialité</p>

                            <p className='fs-18'>
                                Il se peut qu’il vous soit demandé votre adresse e-mail, nom, prénom, et d’autres informations sur le Site, par le biais de la newsletter, ou pour bénéficier d’une offre, ou pour la soumission de votre contribution dans le cadre de « partenariat ».

                                De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés.

                                Les informations sont conservées par <span className='clr-gold'>HBeauty</span> pour une durée illimitée. Nous tenons à vous informer que vous disposez, à l’égard de toutes ces informations et notamment des données personnelles collectées ou générées préalablement, au cours ou à la suite du présent traitement, d’un droit d’accès, de rectification et de retrait.
                            </p>

                            <p className='fw-bold me-2'>Propriété intellectuelle</p>

                            <p className='fs-18'>
                                Le Site et chacun des éléments qui le composent (son arborescence, graphismes, textes, photographies…) relèvent de la législation Marocaine et internationale notamment celle relative au droit d’auteur, aux bases de données et à la propriété intellectuelle.

                                Toute reproduction, représentation, publication, transmission, ou plus généralement toute exploitation non autorisée du Site et/ou de ses éléments, engage votre responsabilité et est susceptible d’entraîner des poursuites judiciaires, sauf autorisation expresse et préalable de Optical Galerie BM, de reproduire et d’utiliser le logos, les articles apparaissant sur le Site, qui sont déposés.

                                Toute violation de la présente clause s’analysera comme une contrefaçon et/ou un acte de concurrence déloyale et pourra entraîner des poursuites civiles et/ou pénales conformément à la loi.
                            </p>

                            <p className='fw-bold me-2'>Droit à l’image</p>

                            <p className='fs-18'>
                                Vous autorisez par la présente, à <span className='clr-gold'>HBeauty</span> de reproduire et exploiter votre image ﬁxée dans le cadre des vidéos et / ou sessions pour la communication et la publication sur les différents réseaux sociaux.
                            </p>

                            <p className='fs-18'>Cette autorisation donne la possibilité à <span className='clr-gold'>HBeauty</span> d’apporter à la ﬁxation initiale de votre image toutes médications, adaptations ou suppressions qu’il jugera utile.  <span className='clr-gold'>HBeauty</span>  pourra notamment l’utiliser, la publier (avec ou sans but lucratif), la reproduire (lors des formations ou des séminaires), l’adapter ou la modiﬁer, seule ou en combinaison avec d’autres matériels, par tous Ies moyens, méthodes ou techniques actuellement connues ou à venir.</p>

                            <p className='fs-18'>Cette autorisation est valable pour :</p>

                            <p className='ps-4'><BsDot className='fs-3 adress-details-icon' /> Durée : illimitée</p>
                            <p className='ps-4'><BsDot className='fs-3 adress-details-icon' /> Territoire : Tous les pays</p>

                            <p className='fs-18'>Sur tous les supports matériels et immatériels, en tous formats connus ou inconnus à ce jour, et notamment, sans que cette liste ne soit exhaustive : support papier (tirages des photographies), catalogues et éditions diverses et autres supports numériques connus et inconnus à ce jour, tout support audiovisuel, et par tous moyens inhérents à ce mode de communication, internet (incluant Intranet, Extranet, Blogs, réseaux sociaux), tous vecteurs de réception confondus (smartphones, tablettes, etc.), médias presse (spots publicitaires télévisuels, spots publicitaires cinématographiques), supports de communication interne, supports promotionnels (PLV, ILV, campagnes d’affichage en tous lieux, toutes dimensions et sur tous supports (urbain, aéroports, gares, transports en commun, etc…), droit d’intégration dans une autre œuvre / œuvre multimédia.</p>

                            <p className='fs-18'>La présente clause d’exploitation de votre droit à l’image est consentie à titre gratuit.</p>

                            <p className='fw-bold me-2'>Liens</p>

                            <p className='fs-18'>Ce Site peut contenir des liens vers d’autres sites que nous n’exploitons pas. Nous ne pouvons en aucune manière être tenus responsables de la mise à disposition de ces liens permettant l’accès à ces sites et sources externes, et ne pouvons supporter aucune responsabilité quant au contenu, publicités, produits, services ou tout autre matériel disponible sur ou à partir de ces sites ou sources externes qui ne sont ni vérifiées ni approuvées par nos équipes.</p>

                            <p className='fw-bold me-2'>Responsabilités</p>

                            <p className='fs-18'>Nous nous engageons à assurer nos meilleurs efforts pour offrir des informations actualisées et exactes. Cependant, nous ne saurions être tenus pour responsables d’erreurs, d’omissions ou des résultats qui pourraient être obtenus par un mauvais usage de ces informations.</p>

                            <p className='fs-18'>L’Éditeur est soumis à une obligation de moyens envers les Utilisateurs, dans la fourniture du Site et des Services et leur fonctionnement. L’Éditeur se réserve néanmoins la possibilité d’effectuer des interventions techniques, de maintenance ou d’actualisation sur le Site et les Services, occasionnant une interruption temporaire d’accès.</p>

                            <p className='fs-18'>L’équipe s’engage à répondre dans les meilleurs délais aux demandes et réclamations des Utilisateurs, demandes de retrait ou de modification des contenus publiés sur le Site et les Services.</p>

                            <p className='fw-bold me-2'>Divers :</p>

                            <p className='fs-18'>Tout litige relatif à la validité, à l’interprétation ou à l’exécution des présentes conditions générales d’utilisation sera soumis aux juridictions marocaines compétentes.</p>

                            <p className='fs-18'><span className='clr-gold'>HBeauty</span> se réserve le droit de modifier à tout moment les présentes conditions générales d’utilisation. Ces conditions sont consultables à tout moment sur le Site et peuvent être imprimées.</p>

                            <p className='fs-18'><span className='clr-gold'>HBeauty</span> invite à cet effet l’utilisateur à prendre régulièrement connaissance des conditions générales d’utilisation.</p>

                            <p className='fs-18'>Pour toute requête relative au contenu du Site ou à l’application des présentes conditions générales d’utilisation, l’utilisateur peut adresser un mail à : info@hbeauty.ma  ou utiliser notre formulaire <Link className='hb-link clr-blue' to='https://hbeauty.ma/contact'>https://hbeauty.ma/contact</Link>.</p>
                        </p>
                    </div>
                </div>
            </div>


            <Footer />
        </>
    );
};

export default TermsOfUse;
