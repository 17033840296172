import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import { BsEyeFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import { TruncateText, apiUrl } from '../../helpers/ApiRequest';
import { FrCustomeErrorNorify } from '../../helpers/Toastify';
import { MdShoppingCart } from 'react-icons/md'
import DottedLoading from '../Loading/DottedLoading';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import './ProductCardStyle.css'
import useApiRequest from '@mbs-dev/api-request';
import discountIcon from '../../../assets/images/checkout/price-discount.png'

type ProductCardProps = {
  item: any;
  bsCol?: string;
  openModal: (product: any) => void;
  isGrid?: boolean;
  className?: string;
  productIdsObj: {
    productIds: number[];
    isProductIds: boolean;
  };
};

const ProductCard: React.FC<ProductCardProps> = ({ productIdsObj, item, openModal, isGrid, className, bsCol = 'col-md-6 col-lg-6 col-xl-4 col-xxl-3 col-sm-6' }) => {
  const { addToCart } = useShoppingCart();
  const { apiRequest } = useApiRequest();
  const [discount, setDiscount] = useState(null);
  const [isGetDiscount, setIsGetDiscount] = useState(false);
  const [haveDiscount, setHaveDiscount] = useState(false);
  const [haveDiscountPrice, setHaveDiscountPrice] = useState(false);
  const [price, setPrice] = useState<number>(item.tarif_regulier);
  const { windowWidth } = useContext(WindowWidthContext)
  const [discountPrice, setDiscountPrice] = useState<number | null>(null);

  const fetchData = async () => {
    try {
      setIsGetDiscount(false)
      if (productIdsObj.isProductIds) {
        if (productIdsObj.productIds.length > 0) {
          if (productIdsObj.productIds.includes(item.id)) {

            const response = await apiRequest({
              route: `${apiUrl}/promotion-discount/product_id/${item.id}`,
              method: 'GET'
            });

            if (response.status === 200) {
              setIsGetDiscount(true)
              if (response.data.discount !== null) {
                setDiscount(response.data.discount)
                setHaveDiscount(true)
                setPrice(item.tarif_regulier - (item.tarif_regulier * (response.data.discount / 100)))
              }
              else if (response.data.price !== null) {
                setHaveDiscountPrice(true)
                setPrice(response.data.price)
              }
              else {
                setPrice(item.tarif_regulier)
              }
            } else {
              setIsGetDiscount(false)
              setHaveDiscount(false)
              setHaveDiscountPrice(false)
              FrCustomeErrorNorify()
            }
          }// End check includes if
          else {
            setIsGetDiscount(true)
            setHaveDiscount(false)
            setPrice(item.tarif_regulier)
          }
        } else {
          setIsGetDiscount(true)
        }
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    fetchData();
  }, [item.id, productIdsObj.productIds, haveDiscount])


  return (
    <div className={`${bsCol} ${isGrid ? 'col-6 isgrid' : 'col-12 isrow'} product-item`} key={item.slug}>
      <div className="container">
        <div className="row d-flex-center">
          <div className="product-image text-center d-flex justify-content-center">

            {haveDiscount
              ?
              <span className='position-fixed discount-value '>{`-${discount} %`}</span>
              // :
              // haveDiscountPrice ?
              //   <span className='position-fixeddiscount-value '>
              //     <img src={discountIcon} alt="promo" className='discount-price-icon' />
              //   </span>
                :
                ''
            }

            <div className="view-details position-fixed c-pointer">
              <div className="eye d-flex-center text-center">
                <div className="view-details-content d-flex-center c-pointer" onClick={() => openModal(item)}>
                  <BsEyeFill className="view-details-eye-icon" />
                </div>
              </div>
            </div>

            <Link to={`/produit/${item.slug}`} state={item}>
              <img alt={item.title} src={`${process.env.REACT_APP_API_UPLOAD}/product_images/${item.image}`} className="product-img mb-5" />
            </Link>
          </div>
          <div className="text-center">
            <div className="eye d-flex-center text-center d-none">
              <div className="view d-flex-center" onClick={() => openModal(item)}>
                <AiOutlineEye className="eye-icon" />
              </div>
            </div>
            <div className="product-details text-center">
              <div className={`product-info ${windowWidth < 768 && windowWidth > 578 ? className : ''}`}>
                <Link className='nav-link' to={`/produit/${item.slug}`} state={item}>
                  <div className="product-title"><TruncateText text={item.title} maxLength={55} /></div>
                  {/* <div className="product-title">{item.title}</div> */}

                </Link>


                <div className="add-to-card-container fixed-bottom row justify-content-between align-items-center mb-1">
                  <hr className='hr-tag m-hr' />
                  <div className="col-8 text-start">
                    <div className="product-price fixedbottom ff-0 fw-600">
                      {/* {productPrice !== item.tarif_regulier */}
                      {
                        haveDiscount || haveDiscountPrice
                          ?
                          <>
                            <p className='m-0'><span className='clr-orange'>{price.toFixed(2)} DH</span></p>
                            <p className='p-discount-price'><del className='me-2 discount-price ff-0 fw-600'>{item.tarif_regulier} DH</del></p>
                          </>
                          :
                          <span>{price} DH</span>
                      }

                    </div>


                  </div>
                  <div className="col-4 text-end shopping-cart-add-to d-flex justify-content-end ">

                    {
                      isGetDiscount ?

                        <div
                          className="shopping-cart-icon-parent d-flex-center c-pointer"
                          onClick={() => addToCart(item.id, price, 'product', 1, item.tarif_regulier)}
                        >
                          <MdShoppingCart className='shopping-cart-icon' />
                        </div>
                        :
                        <div className="loading-discount">
                          <DottedLoading />
                        </div>
                    }

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;