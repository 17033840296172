import React from 'react';
import { Rating } from 'react-simple-star-rating'
import './ReviewsStyle.css'

interface ReviewItemProps {
  name: string;
  comment: string;
  rating: number;
  avatar?: string;
  dateCreate?: string
}

const ReviewItem: React.FC<ReviewItemProps> = ({ name, comment, rating, avatar, dateCreate }) => {
  return (
    <div className="col-lg-10 col-xl-10 col-12">
      <div className="d-flex justify-content-between">
        {/* <div className="avatar-image">
          <img src={avatar} alt="" className="review-avatar" />
        </div> */}
        <div className="review-content mt-2">
          <p className="fw-bold">{name}</p>
          <p>{comment}</p>
          {dateCreate && (<p>{dateCreate}</p>)}
        </div>
        <div className=""></div>
        <div className=""></div>
        <div className=""></div>
        <div className="review-rating">
          <Rating initialValue={rating} readonly />
        </div>
      </div>
      <hr className="hr-tag" />
    </div>
  );
};

export { ReviewItem };