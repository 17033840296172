import React, { ChangeEvent, FocusEvent } from 'react';
import './InputBoxStyle.css'

interface InputBoxProps {
    label?: React.ReactNode | string;
    spanValue?: string;
    name?: string;
    value?: string;
    className?: string;
    textarea?: boolean;
    rows?: number;
    type?: string;
    required?: boolean;
    touched?: any;
    errors?: any;
    handleChange?: (event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
    handleBlur?: (event: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => void;
}

const InputBox: React.FC<InputBoxProps> = ({
    label = "",
    spanValue = "",
    name = "",
    textarea = false,
    rows = 6,
    value = "",
    className = "",
    type = "text",
    required = false,
    touched = {},
    errors = {},
    handleChange = () => { },
    handleBlur = () => { },
}) => {
    return (
        <div className={`input-box`}>
            <label htmlFor={name} className={`form-label ${value !== '' ? 'label-display' : ''}`}>
                {label}
            </label>

            {textarea ? (
                <textarea
                    name={name}
                    className={`${value ? 'input-box-input' : ''} ${className}`}
                    onChange={handleChange}
                    // onChange={handleChange as React.ChangeEventHandler<HTMLTextAreaElement>}
                    onBlur={handleBlur}
                    // onBlur={handleBlur as React.FocusEventHandler<HTMLTextAreaElement>}
                    value={value}
                    required={required}
                    rows={rows}
                />
            ) : (
                <input
                    type={type}
                    name={name}
                    className={`${value ? 'input-box-input' : ''} ${className}`}
                    onChange={handleChange}
                    // onChange={handleChange as React.ChangeEventHandler<HTMLTextAreaElement>}
                    onBlur={handleBlur}
                    // onBlur={handleBlur as React.FocusEventHandler<HTMLTextAreaElement>}
                    value={value}
                    required={required}
                />
            )}

            <span>{spanValue}</span>

            {touched[name] && errors[name] ? (
                <div className="invalid-feedback">{errors[name]}</div>
            ) : null}
        </div>
    );
};


export default InputBox;
