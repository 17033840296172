import { FC } from 'react'
import { GrFormClose } from 'react-icons/gr';
import './OffCanvasRight.css'

interface OffCanvasRightProps {
    content: any
    label: string
    id: string
    closeBtnRef: React.RefObject<HTMLButtonElement>
}

const OffCanvasRight: FC<OffCanvasRightProps> = ({ content, label, id, closeBtnRef }) => {

    return (
        <div className={`offcanvas offcanvas-end`} tabIndex={-1} id={id} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header">
                <h5 id="offcanvasRightLabel" className='clr-dark text-upper'>{label}</h5>
                <button
                    ref={closeBtnRef}
                    type="button"
                    className="btn offcanvas-close-btn text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close">
                    <GrFormClose className='offcanvas-close-icon clr-dark' />
                </button>
            </div>
            <div className="offcanvas-body">
                {content}
            </div>
        </div>
    )
}

export default OffCanvasRight