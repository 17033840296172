import { FC } from 'react';
import './ProductImagesGalleryStyle.css'
import ImageGallery from 'react-image-gallery';
import { ProductProps } from '../../../helpers/Props';

interface ProductImagesGalleryProps {
  product: ProductProps;
}

const ProductImagesGallery: FC<ProductImagesGalleryProps> = ({ product }) => {

  const images = product
    ? product.productImages.map(image => ({
      original: `${process.env.REACT_APP_API_UPLOAD}/product_multi_images/${image.image}`,
      thumbnail: `${process.env.REACT_APP_API_UPLOAD}/product_multi_images/${image.image}`,
    }))
    : [];

  if (!product) {
    return null;
  }

  return (
    <div>
      <ImageGallery
        items={images}
        lazyLoad={true}
      />
    </div>
  );
};

export default ProductImagesGallery;