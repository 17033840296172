import { FC, useContext, useEffect, useState } from 'react';
// import Modal from '../modal/ModalContent';
import HeaderContainer from '../HeaderContainer/HeaderContainer';
import useApiRequest from '@mbs-dev/api-request';
import { PackProps } from '../../helpers/Props';
import PackCard from '../PackCard/PackCard';
import { Link } from 'react-router-dom';
import { BsGridFill } from 'react-icons/bs'
import { CiGrid2H } from 'react-icons/ci'
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import { apiUrl } from '../../helpers/ApiRequest';

const PopularePacks: FC = () => {

  const [packs, setPacks] = useState<PackProps[]>([]);
  const { apiRequest } = useApiRequest()
  const [isGrid, setIsGrid] = useState(true);
  const [isFilter, setIsFilter] = useState(true);
  const { windowWidth } = useContext(WindowWidthContext)


  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/populare-packs`,
        method: 'GET'
      });

      if (response.status === 200) {
        setPacks(response.data)
      }

    } catch (error) {

    }
  };
  useEffect(() => {
    fetchData();
  }, [])


  return (
    <>
      {
        packs.length > 0 ?
          <div className="productss pack-container mt-6">

            <div className="container product--container">

              <HeaderContainer className="fw-bold fs-1 mt-5" title="Les Packs Les Plus Vendus" />

              <div className={`row d-flex-center  ${isFilter ? 'home-filter m-filter-rowx' : 'm-filter-row-active'} ${windowWidth > 578 ? 'd-none' : ''}`}>
                <div className={`col-12 text-end p-0 fixwidth ${windowWidth > 578 ? 'd-none' : ''} ${isFilter ? '' : 'm-grid-filter'}`}>
                  <button className='btn hb-small-btn hb-primary-btn me-3 ' onClick={() => setIsGrid(false)}>
                    <CiGrid2H className='hb-icon hb-primary-icon' />
                  </button>
                  <button className='btn hb-small-btn hb-primary-btn me-2' onClick={() => setIsGrid(true)}>
                    <BsGridFill className='hb-icon hb-primary-icon' />
                  </button>
                </div>
              </div>

              <div className="row justify-content-center">
                {packs.map((item) => (
                  <PackCard key={item.title} item={item} isGrid={isGrid} />
                ))}


              </div>
              <div className="d-flex justify-content-center">
                <Link to='/boutique/packs' className="btn-loading mt-5 d-flex justify-content-center" >
                  Chargement Plus
                </Link>
              </div>
            </div>
          </div>
          :
          ''
      }
      {/* {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />} */}
    </>
  );
};

export default PopularePacks;