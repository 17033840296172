import React, { useEffect, useState } from 'react';
import Loading from '../components/Loading/Loading';

const PackBoutique = React.lazy(() => import('../pages/Boutique/PackBoutique'));

const LazyPackBoutique: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <React.Suspense fallback={<Loading />}>
          <PackBoutique />
        </React.Suspense>
      )}
    </div>
  );
};

export default LazyPackBoutique;