import { FC, useContext, useEffect, useState } from 'react';
import Modal from '../DetailsProductModal/ModalContent';
import HeaderContainer from '../HeaderContainer/HeaderContainer';
import useApiRequest from '@mbs-dev/api-request';
import { ProductProps } from '../../helpers/Props';
import { useProductIds } from '../../hooks/useProductIds';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import ProductSwiper from '../Carousel/ProductSwiper/ProductSwiper';
import { apiUrl } from '../../helpers/ApiRequest';

const PopulareProducts: FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [products, setProducts] = useState<ProductProps[]>([]);
  const { apiRequest } = useApiRequest()
  const [isGrid, setIsGrid] = useState(true);
  const [isFilter, setIsFilter] = useState(true);
  const { windowWidth } = useContext(WindowWidthContext)

  const { productIds, isProductIds } = useProductIds();

  const openModal = (product: any) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const fetchData = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/products.json?popularProduct=enable`,
        method: 'GET'
        
      });

      if (response.status === 200) {
        setProducts(response.data)
      }
    } catch {
    }
  };

  useEffect(() => {
    fetchData();
  }, [])


  return (
    <>
      {products.length > 0 ?
        <div className="productss populare-products">
          <div className="container product--container">
            <HeaderContainer className="fw-bold fs-1" title="Nos Produits Populaires" />
            <div className="row justify-content-center">
              <ProductSwiper items={products} itemType='product' />
            </div>
          </div>
        </div>
        :
        null
      }
      {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />}
    </>
  );
};

export default PopulareProducts;