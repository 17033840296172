import React, { createContext, useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { SliderProps } from '../helpers/Props';

const SliderContext = createContext<{
    sliders: SliderProps[];
    isFetching: boolean;
}>({
    sliders: [],
    isFetching: false,
});

export const useSliderContext = () => useContext(SliderContext);

export const SlidersProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {

    const {
        data,
        isLoading: isFetching,
    } = useInfiniteQuery<SliderProps[], Error>(
        'sliders',
        async () => {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/sliders.json`);

            return response.data;

        },
        {
            getNextPageParam: (lastPage, pages) => pages.length + 1,
        }
    );

    const sliders = data?.pages.flat() || [];


    return (
        <SliderContext.Provider value={{
            sliders,
            isFetching: isFetching
        }}>
            {children}
        </SliderContext.Provider>
    );
};
