import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import checkoutImage from '../../../assets/images/checkout/promo-2-removebg-preview.png'
import emptyCart from '../../../assets/images/checkout/empty-cart.png'
import mastercard from '../../../assets/images/checkout/master.png'
import visacard from '../../../assets/images/checkout/visa.png'
// import cash from '../../../assets/images/checkout/cash.png'
import cash from '../../../assets/images/checkout/cash-on-delivery.png'
import { ShoppingCartItem } from '../../components/ShoppingCartItem/ShoppingCartItem';
import InputBox from '../../components/InputBox/InputBox';
import * as yup from "yup";
import { useFormik } from "formik";
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import { Link, useNavigate } from 'react-router-dom';
import { UFormatDate, apiUrl } from '../../helpers/ApiRequest';
import { InfoNotify, notify, ErrorNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { BsCheckCircleFill, BsCircle, BsPatchCheck, BsStars } from 'react-icons/bs'
import { Helmet } from 'react-helmet-async';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import { AuthUserProps } from '../../helpers/Props';
import './ShoppingCart.css';
import useApiRequest from '@mbs-dev/api-request';

const ShoppingCart: React.FC = () => {

    const user_token = localStorage.getItem('hb_user_token');
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState('livraison');
    const [expeditionOption, setExpeditionOption] = useState('forfait');
    const { cartItems, total, cartQuantity, setOrderData, updateCartItemsWithPromotions } = useShoppingCart()
    const [CPName, setCPName] = useState('');
    const [CPDiscount, setCPDiscount] = useState(0);
    let [newTotal, setNewTotal] = useState(total);
    const [codePromoState, setCodePromoState] = useState(false);
    const { apiRequest } = useApiRequest();
    const today = new Date();
    const { windowWidth } = useContext(WindowWidthContext)
    localStorage.setItem('isguarded', 'true')


    const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
    const storedUser = localStorage.getItem('hb_user');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (storedUser) {
            setAuthUser(JSON.parse(storedUser));
        }
    }, []);


    const promoValidationSchema = yup.object({
        codepromo: yup.string().required('Ce champ est obligatoire'),
    });

    const promoFormik = useFormik({
        initialValues: {
            codepromo: "",
        },
        validationSchema: promoValidationSchema,
        onSubmit: async (values) => {
            try {
                const response = await apiRequest({
                    route: `${apiUrl}/codepromo/cpname/${values.codepromo}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`
                });

                if (codePromoState === false) {
                    if (response.data.message === 'Found') {

                        if (UFormatDate(response.data.codepromo.cp_date_fin) >= UFormatDate(today)) {
                            notify('Code promo appliqué avec succès');
                            setCPName(response.data.codepromo.cp_name);
                            setCPDiscount(response.data.codepromo.cp_discount)
                            setCodePromoState(true);
                        }
                        else if (UFormatDate(response.data.codepromo.cp_date_fin) < UFormatDate(today)) {
                            ErrorNotify('Ce code promo est expiré');
                        }
                    }
                }

                else if (codePromoState === true) {
                    InfoNotify('Code promo dejà appliqué');
                }

                if (response.data.message === 'Not Found') {
                    ErrorNotify("Code promo n'existe pas");
                }


            } catch (error) {
                FrCustomeErrorNorify()
            }
        },
    });

    const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPaymentMethod(event.target.value);
    };

    const handleExpeditionOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setExpeditionOption(event.target.value);
    };

    const deleteCodePromo = () => {
        setCodePromoState(false)
        notify('Code promo bien supprimé')
    }

    const valider = async () => {
        if (!user_token) {
            navigate('/login');
            InfoNotify('Vous devrez être connecté pour effectuer cette action!');
        }
        else {
            const response = await apiRequest({
                route: `${apiUrl}/user/check-user-info/${authUser?.id}`,
                method: 'GET',
                requiresAuth: true,
                token: `${localStorage.getItem('hb_user_token')}`
            });

            if (response.status === 200) {
                if (response.data.result === 'true') {
                    navigate('/checkout');
                }
                else {
                    navigate('/my-account/edit-account')
                    InfoNotify('Merci de compléter les details de votre compte')
                }
            }

        }


    }

    useEffect(() => {

        if (paymentMethod === 'carte') {
            if (codePromoState === true) {
                setNewTotal(total - (total * ((CPDiscount + 5) / 100)))
            }
            if (codePromoState === false) {
                setNewTotal(total - (total * 0.05))
            }
        }

        if (paymentMethod === 'livraison') {
            if (codePromoState === true) {
                setNewTotal(total - ((total * CPDiscount) / 100))
            }
            if (codePromoState === false) {
                setNewTotal(total)
            }
        }

        if (newTotal >= 300) {
            const order = {
                paymentMethod: paymentMethod,
                totalPrice: newTotal
            }

            setOrderData(order);
        }

        if (newTotal < 300) {
            if (paymentMethod === 'livraison') {
                const order = {
                    paymentMethod: paymentMethod,
                    totalPrice: newTotal + 50
                }

                setOrderData(order);
            }

            if (paymentMethod === 'carte') {
                const order = {
                    paymentMethod: paymentMethod,
                    totalPrice: newTotal + 50
                }

                setOrderData(order);
            }

        }

    }, [CPDiscount, total, codePromoState, newTotal, paymentMethod])

    useEffect(() => {
        updateCartItemsWithPromotions()
    }, [])

    return (
        <>
            <Helmet>
                <title>Mon panier</title>
            </Helmet>
            <TopHeader />
            <Navbar />

            <div className="shopping-card d-flex justify-content-betdwen align-items-center">
                <div className="container product-cdontainer">
                    <HeaderContainer className='fw-bold fs-1 mt-5' title="Mon panier" />
                    {
                        cartQuantity < 1 ?
                            <>
                                <div className="row pt-5">
                                    <div className="col-12 d-flex justify-content-center align-items-center col-">
                                        <img src={emptyCart} alt="empty cart" className='shopping-card-empty-image' />
                                    </div>
                                    <div className="col-12 mt-3 d-flex justify-content-center align-items-center col-">
                                        <h3>Votre panier est actuellement vide.</h3>
                                    </div>
                                    <div className="col-12 mt-3 d-flex justify-content-center align-items-center col-">
                                        <Link to='/boutique' className='nav-link back-to-boutique'>RETOUR À LA BOUTIQUE</Link>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                                <div className={`row ${windowWidth < 578 ? 'px-3' : ''}`}>

                                    <div className="col-md-12 col-lg-12 col-xl-7 pb-0 shopping-card-items">
                                        <div className="items">
                                            {cartItems.map(item => (
                                                <ShoppingCartItem key={item.product} {...item} />
                                            ))}
                                        </div>
                                        <form onSubmit={promoFormik.handleSubmit}>

                                            <div className="promo">
                                                <div className="code-promo d-flex justify-content-between align-items-center flex-wrap">
                                                    <div className="code-promo-value d-flex ms-1 align-items-center">
                                                        <div className="code-promo-image">
                                                            <img src={checkoutImage} alt="code promo" />
                                                        </div>
                                                        <div className="code-promo-input ms-2">
                                                            <div className="form-group">
                                                                <InputBox
                                                                    spanValue="Code promo"
                                                                    name="codepromo"
                                                                    value={promoFormik.values.codepromo}
                                                                    type='text'
                                                                    className={promoFormik.touched.codepromo && promoFormik.errors.codepromo ? "is-invalid" : ""}
                                                                    touched={promoFormik.touched}
                                                                    errors={promoFormik.errors}
                                                                    handleChange={promoFormik.handleChange}
                                                                    handleBlur={promoFormik.handleBlur}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="code-promo-btn">
                                                        <SubmitButton
                                                            className="mt-1 px-4"
                                                            btnLabel="APPLIQUER LE CODE PROMO"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                    <div className={`col-md-12 col-lg-12 col-xl-5 ${windowWidth < 1200 ? 'p-0' : ''}`}>
                                        <div className="shopping-card-checkout">

                                            <div className="row">
                                                <div className="col-12">
                                                    <p className="shopping-cart-note">
                                                        <BsStars className='shopping-cart-note-icon' />
                                                        Livraison gratuite a partir de : 300 DH
                                                    </p>
                                                </div>
                                                {/* <div className="col-12 d-none">
                                                    <p className="shopping-cart-note">
                                                        <BsStars className='shopping-cart-note-icon' />
                                                        Livraison gratuite en cas de paiement par carte bancaire
                                                    </p>
                                                </div> */}
                                                <div className="col-12">
                                                    <p className="shopping-cart-note">
                                                        {/* <BsPatchCheck className='shopping-cart-note-icon' /> */}
                                                        <BsStars className='shopping-cart-note-icon' />
                                                        Remise de 5% en cas de paiement par carte bancaire
                                                    </p>
                                                </div>
                                            </div>
                                            <hr className='hr-tag' />

                                            <div className="row">

                                                <div className="shopping-card-checkout-title">
                                                    <span>TOTAL PANIER</span>
                                                    <hr className='hr-tag' />
                                                </div>

                                                <div className="shopping-card-checkout-soustotal d-flex justify-content-between px-5 mt-2 mb-3">
                                                    <div className="shopping-card-checkout-soustotal-title">SOUS-TOTAL</div>
                                                    <div className="shopping-card-checkout-soustotal-value">{total.toFixed(2)} DH</div>
                                                </div>
                                                <hr className='hr-tag' />

                                                <div className=" shopping-card-checkout-expedition d-flexx justify-content-betweenn px-5 mt-2 mb-3">
                                                    <div className="row d-nonex">

                                                        <div className="col-12 col-lg-4 col-md-12">
                                                            <div className="shopping-card-checkout-expedition-title mb-2">
                                                                <span>EXPÉDITION</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-8 col-md-12">

                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="forfait mb-3">
                                                                        {
                                                                            paymentMethod === 'carte' && Number(total.toFixed(2)) < 300 &&
                                                                            // (<BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                            (<BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)

                                                                        }
                                                                        {
                                                                            paymentMethod !== 'carte' && Number(total.toFixed(2)) < 300 &&
                                                                            (<BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)
                                                                        }
                                                                        {
                                                                            paymentMethod === 'carte' && Number(total.toFixed(2)) >= 300 &&
                                                                            (<BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                        }
                                                                        {
                                                                            paymentMethod !== 'carte' && Number(total.toFixed(2)) >= 300 &&
                                                                            (<BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                        }
                                                                        {/* {
                                                                            paymentMethod === 'carte'
                                                                                ? Number(total.toFixed(2)) < 300
                                                                                    ?
                                                                                    <BsCheckCircleFill className='clr-success shopping-cart-free-icon' />
                                                                                    :
                                                                                    <BsCircle className='clr-danger shopping-cart-free-icon' />
                                                                                :
                                                                                <BsCheckCircleFill className='clr-success shopping-cart-free-icon' />

                                                                        } */}
                                                                        <span className='shopping-cart-livraison  '>Forfait de livraison : 50 DH</span>
                                                                    </div>

                                                                </div>
                                                                <div className="col-12">

                                                                    <div className="forfait">
                                                                        {
                                                                            paymentMethod === 'carte' && Number(total.toFixed(2)) < 300 &&
                                                                            // (<BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)
                                                                            (<BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                        }
                                                                        {
                                                                            paymentMethod !== 'carte' && Number(total.toFixed(2)) < 300 &&
                                                                            (<BsCircle className='clr-danger shopping-cart-free-icon' />)
                                                                        }
                                                                        {
                                                                            paymentMethod === 'carte' && Number(total.toFixed(2)) >= 300 &&
                                                                            (<BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)
                                                                        }
                                                                        {
                                                                            paymentMethod !== 'carte' && Number(total.toFixed(2)) >= 300 &&
                                                                            (<BsCheckCircleFill className='clr-success shopping-cart-free-icon' />)
                                                                        }

                                                                        {/* {
                                                                            paymentMethod === 'carte'
                                                                                ? Number(total.toFixed(2)) < 300
                                                                                    ?
                                                                                    <BsCheckCircleFill className='clr-success shopping-cart-free-icon' />
                                                                                    :
                                                                                    <BsCheckCircleFill className='clr-success shopping-cart-free-icon' />
                                                                                :
                                                                                <BsCircle className='clr-danger shopping-cart-free-icon' />
                                                                        } */}
                                                                        <span className='shopping-cart-livraison  '>Livraison Gratuit</span>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div className="form-check d-none">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input shopping-form-check-input"
                                                                        type="radio"
                                                                        name="expedition"
                                                                        id="forfait"
                                                                        value="forfait"
                                                                        checked={expeditionOption === 'forfait'}
                                                                        onChange={handleExpeditionOptionChange}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="forfait">
                                                                        FORFAIT: 50.00 DH
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="form-check d-none">
                                                                <input
                                                                    className="form-check-input shopping-form-check-input"
                                                                    type="radio"
                                                                    name="expedition"
                                                                    id="gratuit"
                                                                    value="gratuit"
                                                                    checked={expeditionOption === 'gratuit'}
                                                                    onChange={handleExpeditionOptionChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="gratuit">
                                                                    LIVRAISON GRATUITE
                                                                </label>
                                                            </div>

                                                            {/* <div className="row shopping-card-checkout-expedition-note mt-3 d-none">
                                                                <span>Les options de livraison seront mises à jour lors de la commande.</span>
                                                            </div> */}

                                                            {/* <div className="row shopping-card-checkout-expedition-btn mt-3 d-none">
                                                                <SubmitButton
                                                                    className="mt-1 px-5"
                                                                    btnLabel="CALCULER LES FRAIS" // D'EXPÉDITION
                                                                />
                                                            </div> */}
                                                        </div>

                                                    </div>

                                                    {codePromoState && (
                                                        <>
                                                            <hr className='hr-tag' />

                                                            <div className="row justify-content-between">
                                                                <div className="col-12">
                                                                    <div className="shopping-card-checkout-soustotal-title">Code Promo : <span className='text-danger'>{CPName}</span></div>
                                                                    <div className="shopping-card-checkout-soustotal-title">Remise : <span className='text-danger'>{CPDiscount}%</span></div>

                                                                </div>
                                                                <div className="col-12 text-end">
                                                                    <button onClick={() => deleteCodePromo()} className="btn-delete-codepromo mt-5" >
                                                                        Supprimer
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}

                                                </div>
                                                <hr className='hr-tag' />

                                                <div className="shopping-card-checkout-payment mb-3">
                                                    <div className="row d-flex justify-content-center align-items-center text-center">
                                                        <span className='text-center mb-4'>Methode de payment</span>
                                                        <div className="col-6 col-md-4 col-lg-4  align-items-center text-center d-fDlex">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input shopping-form-check-input"
                                                                    type="radio"
                                                                    name="payment"
                                                                    id="livraison"
                                                                    value="livraison"
                                                                    checked={paymentMethod === 'livraison'}
                                                                    onChange={handlePaymentMethodChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="livraison">
                                                                    À la livraison
                                                                </label>
                                                            </div>
                                                            <label htmlFor="livraison">
                                                                <img src={cash} alt="cash" className='shopping-card-checkout-payment-mastercard' />
                                                            </label>
                                                        </div>
                                                        <div className="col-6 col-md-4 col-lg-4  align-items-center text-center d-non">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input shopping-form-check-input"
                                                                    type="radio"
                                                                    name="payment"
                                                                    id="carte"
                                                                    value="carte"
                                                                    checked={paymentMethod === 'carte'}
                                                                    onChange={handlePaymentMethodChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="carte">
                                                                    Carte bancaire
                                                                </label>
                                                            </div>
                                                            <label htmlFor="carte">
                                                                <img src={mastercard} alt="mastercard" className='shopping-card-checkout-payment-mastercard' />
                                                                <img src={visacard} alt="visa" className='shopping-card-checkout-payment-mastercard' />
                                                            </label>

                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className='hr-tag' />

                                                <div className="row">
                                                    <div className="shopping-card-checkout-total d-flex justify-content-between px-5 mb-5">
                                                        <div className="shopping-card-checkout-total-title px-">TOTAL</div>
                                                        <div className="shopping-card-checkout-total-value">
                                                            {
                                                                paymentMethod === 'carte'
                                                                    ? Number(newTotal.toFixed(2)) < 300
                                                                        ?
                                                                        (newTotal + 50).toFixed(2)
                                                                        :
                                                                        newTotal.toFixed(2)
                                                                    : Number(newTotal.toFixed(2)) < 300
                                                                        ?
                                                                        (newTotal + 50).toFixed(2)
                                                                        :
                                                                        newTotal.toFixed(2)
                                                            }
                                                            DH</div>

                                                    </div>

                                                    <div className="row d-flex justify-content-start">
                                                        <div className={`text-start ${windowWidth < 578 ? 'col-12' : 'col-10'}`}>
                                                            <SubmitButton
                                                                className="mt-1 px-5"
                                                                btnLabel="Valider la commande"
                                                                onClick={() => (valider())}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </div>

            {/* <Footer /> */}
        </>
    )
}

export { ShoppingCart };