import React, { FC, useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../assets/images/logo/logo-1.svg';
import emptyCart from '../../../assets/images/checkout/empty-cart.png'
import { FiShoppingCart } from 'react-icons/fi';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { SubShoppingCart } from '../SubShoppingCart/SubShoppingCart';
import { MdShoppingCartCheckout, MdOutlineHelpOutline } from 'react-icons/md';
import { WindowWidthContext, ContextProps } from '../../contexts/WindowWidthProvider'
import { useMarquesContext } from '../../contexts/MarquesContext';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import useApiRequest from '@mbs-dev/api-request';
import { ProductProps } from '../../helpers/Props';
import ProductCard from '../ProductCard/ProductCard';
import Modal from '../DetailsProductModal/ModalContent';
import emptyBox from '../../../assets/images/bootique/empty-box.png'
import { useProductIds } from '../../hooks/useProductIds';
import useClickOutside from '../../hooks/useClickOutside';
import OffCanvasRight from '../OffCanvasRight/OffCanvasRight';
import './NavBarStyle.css'
import { apiUrl } from '../../helpers/ApiRequest';

let timeout: NodeJS.Timeout;
let marquetTimeout: NodeJS.Timeout;

const Navbar: FC = () => {

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
      clearTimeout(marquetTimeout);
    };
  }, []);

  // Global
  const navigate = useNavigate();
  const { apiRequest } = useApiRequest()
  const { productIds, isProductIds } = useProductIds();

  // Contexts
  const { windowWidth } = useContext<ContextProps>(WindowWidthContext)
  const { marques } = useMarquesContext();
  const { categories } = useCategoriesContext();
  const { cartItems, cartQuantity, total, updateCartItemsWithPromotions } = useShoppingCart()
  const cartItemsQuantity = cartItems.length;

  // States
  const [searchBoxVisible, setSearchBoxVisible] = useState(false);
  const [ShoppingCartBoxVisible, setShoppingCartBoxVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMarqueDropdownOpen, setIsMarqueDropdownOpen] = useState(false);
  const [products, setProducts] = useState<ProductProps[]>([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [textValue, setTextValue] = useState('');

  // ShoppingCartBox close
  const elementRef = useRef<HTMLDivElement>(null);
  const blockRef = useRef<HTMLDivElement>(null);

  // OffCanvas close
  const closeBtnRef = useRef<HTMLButtonElement | null>(null);
  const closeCBtnRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    updateCartItemsWithPromotions()
  }, [ShoppingCartBoxVisible])

  const closeOffCanvas = () => {
    if (closeBtnRef.current) {
      closeBtnRef.current.click();
    }
  };

  const closeCOffCanvas = () => {
    if (closeCBtnRef.current) {
      closeCBtnRef.current.click();
    }
  };

  const handleShoppingCartBoxClose = () => {
    setShoppingCartBoxVisible(false)
  };

  useClickOutside(elementRef, handleShoppingCartBoxClose, blockRef);


  const openModal = (product: any) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleDropdownMouseEnter = () => {
    if (windowWidth > 992) {
      clearTimeout(timeout);
      setIsDropdownOpen(true);
    }
  };


  const handleDropdownMouseLeave = () => {
    if (windowWidth > 992) {
      timeout = setTimeout(() => {
        setIsDropdownOpen(false);
      }, 200);
    }
  };

  const handleMarqueDropdownMouseEnter = () => {
    clearTimeout(marquetTimeout);
    setIsMarqueDropdownOpen(true);
  };

  const handleMarqueDropdownMouseLeave = () => {
    marquetTimeout = setTimeout(() => {
      setIsMarqueDropdownOpen(false);
    }, 200);
  };

  const handleShoppingCartIconClick = () => {
    setShoppingCartBoxVisible(!ShoppingCartBoxVisible);
  };

  const openContact = () => {
    navigate('/contact')
  };
  const openAccount = () => {
    navigate('/my-account')
  };

  const searchProducts = async (text: string) => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/search-products/${text}`,
        method: 'GET'
      });

      if (response.status === 200) {
        setProducts(response.data)
      } else {
        //FrCustomeErrorNorify()
      }

    } catch (error) {
      //FrCustomeErrorNorify()
    }
  };

  useEffect(() => {
    if (!searchBoxVisible) {
      setProducts([])
      setTextValue('');
    }
  }, [searchBoxVisible])

  const marquesContent =
    <>
      <div className="row ox-hidden" >
        {marques.map((item) => (
          <React.Fragment key={item.slug}>
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 d-flex-center flex-wrap" onClick={() => setIsDropdownOpen(false)} >
              <div className="m-2 mb-0 nav-item-image-container">
                <Link to={`/boutique/marque/${item.slug}`} onClick={closeOffCanvas} >
                  <img
                    src={`${process.env.REACT_APP_API_UPLOAD}/marque_images/${item.image}`}
                    alt={item.title}
                    className='nav-item-marque-image' />
                </Link>
              </div>

              <div className="nav-item-title text-center">
                <p className='ff-0 fw-600 m-0'>{item.title}</p>
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
      {/* <hr className='hr-tag' />
      <div className="row ps-3 justify-content-start" onClick={closeOffCanvas}>
        <Link to='/nos-marques' className='btn display-all'>Toutes les marques</Link>
      </div> */}
    </>

  const categoriesContent =
    <>
      <div className="row">
        {categories.map((item, index) => (
          <React.Fragment key={item.slug}>
            {
              // index < 8 ?
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 d-flex-center flex-wrap" onClick={() => setIsDropdownOpen(false)} key={item.slug}>
                <div className="m-2 nav-item-image-container">
                  <Link to={`/boutique/categorie/${item.slug}`} onClick={closeCOffCanvas}>
                    <img
                      src={`${process.env.REACT_APP_API_UPLOAD}/category_images/${item.image}`}
                      alt={item.title}
                      className='nav-item-image' />
                  </Link>
                </div>

                <div className="nav-item-title text-center">
                  <p className='ff-0 fw-600 m-0'>{item.title}</p>
                </div>
              </div>
              // : ''
            }
          </React.Fragment>
        ))}
      </div>
      {/* <hr className='hr-tag' />
      <div className="row  ps-3  justify-content-start" onClick={closeCOffCanvas}>
        <Link to='/nos-categories' className='btn display-all'>Toutes les catégories</Link>
      </div> */}
    </>


  return (
    <>
      <header className="header-nav w-100 d-flex justify-content-start align-items-center">
        <div className="containerr w-100 mx-5 top-container">
          <div className="row justify-content-end">

            <div className="col-xxl-5 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12  mt-2">
              <nav className="navbar navbar-expand-xxl navbar-right">
                <div className="container-fluid d-flex justify-content-start align-items-center">

                  <div className="responsive-icon">
                    <button className={`navbar-toggler ${windowWidth < 992 ? 'collapsed' : ''}`} type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>

                  <div className={`navbar-brand m-logo ${windowWidth < 992 ? 'ms3' : 'ms-0me-4'}`}>
                    <Link to="/">
                      <img src={logo} alt="hbeauty" className='hbeauty hbeauty-logo' />
                    </Link>
                  </div>

                  <div className="w-100 collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                      {/* Start Categorie */}
                      <li
                        className={`nav-item ps-0 dropdown`}
                        onMouseEnter={handleDropdownMouseEnter}
                        onMouseLeave={handleDropdownMouseLeave} >
                        {
                          windowWidth > 992
                            ?
                            <a
                              className="nav-link navbar-link dropdown-toggle"
                              href="#"
                              id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown" aria-expanded="false"
                              aria-label="categories"
                            >
                              NOS Catégories
                            </a>
                            :
                            <a
                              className="nav-link navbar-link dropdownstoggle"
                              href="#"
                              data-bs-toggle="offcanvas"
                              data-bs-target="#offcanvascategorie"
                              aria-controls="offcanvascategorie"
                            >
                              NOS Catégories
                            </a>
                        }

                        {
                          windowWidth > 992
                            ?
                            <ul className={`dropdown-menu dropdown-menu-categorie ${windowWidth > 520 || true ? isDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                              {categoriesContent}
                            </ul>
                            :
                            <OffCanvasRight
                              content={categoriesContent}
                              label='Nos Categories'
                              id='offcanvascategorie'
                              closeBtnRef={closeCBtnRef}
                            />
                        }

                      </li>
                      {/* End Categorie */}

                      {/* Start Marque */}
                      <li className="nav-item ps-0 dropdown"
                        onMouseEnter={handleMarqueDropdownMouseEnter}
                        onMouseLeave={handleMarqueDropdownMouseLeave}
                        onClick={() => setIsDropdownOpen(false)}>

                        {
                          windowWidth > 992 ?
                            <a className="nav-link navbar-link  dropdown-toggle"
                              href="#" id="navbarDropdownMenuLink"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              aria-label="marques"
                            >
                              NOS MARQUES
                            </a>
                            :
                            <a
                              className="nav-link navbar-link dropdownstoggle" href="#"
                              data-bs-toggle="offcanvas" data-bs-target="#offcanvasmarque" aria-controls="offcanvasmarque"
                            >
                              NOS MARQUES
                            </a>
                        }

                        {
                          windowWidth > 992
                            ?
                            <ul className={`dropdown-menu dropdown-menu-categorie ${windowWidth > 520 ? isMarqueDropdownOpen ? 'show' : '' : ''}`} aria-labelledby="navbarDropdownMenuLink">
                              {marquesContent}
                            </ul>
                            :
                            <OffCanvasRight
                              content={marquesContent}
                              label='Nos Marques'
                              id='offcanvasmarque'
                              closeBtnRef={closeBtnRef}
                            />
                        }

                      </li>
                      {/* End Marque */}

                      <li className="nav-item ps-0">
                        <Link className="nav-link navbar-link " to="/boutique">Boutique</Link>
                      </li>

                    </ul>
                  </div>
                </div>
              </nav>
            </div>

            <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12 text-end search-col">
              <input
                type="text"
                value={textValue}
                className="form-controll search-box-input ff-0 ps-2 pb-1"
                placeholder="Cherchez des produits..."
                onChange={(e) => {
                  setTextValue(e.target.value);
                  searchProducts(e.target.value);
                  setSearchBoxVisible(true)
                  if (textValue === '') {
                    setSearchBoxVisible(false);
                  }
                }}
                onBlur={() => {
                  if (textValue === '') {
                    setSearchBoxVisible(false);
                  }
                }}

              />
              {/* <div className="input-group-append me-2">
                <span className="input-group-textt">
                  <FiSearch className="search-box-icon" />
                </span>
              </div> */}
            </div>

            <div className={`col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-8 col-5  text-${windowWidth > 578 ? 'end' : 'center'} dd-flex justify-content-end align-items-center user-header`}>
              <div className={`d-inline-flex text-${windowWidth > 578 ? 'end' : 'center'} mb-1 icon-items m-searchx`}>

                <div className="pe- row" >
                  <div className="col-12 text-center">
                    <Link to='/contact' className='nav-link'>
                      <MdOutlineHelpOutline className="navbar-icon ms-sm-2 navbar-icon-search" />
                    </Link>
                  </div>
                  {
                    windowWidth > 578 ?
                      <div className="col-12 text-center" >
                        <p className='ff-0 fw-600 fs-6 c-pointer text-orange-hover' onClick={() => openContact()}>
                          Assistance
                        </p>
                      </div>
                      :
                      ''
                  }
                </div>

                <div className="pe4 row">
                  <div className="col-12 text-center">
                    <Link className="nav-link user-account-icon" to="/login">
                      <HiOutlineUserCircle className="navbar-icon ms-sm-2" />
                    </Link>
                  </div>
                  {windowWidth > 578 ?
                    <div className="col-12 text-center">
                      <p className='ff-0 fw-600 fs-6 c-pointer text-orange-hover' onClick={() => openAccount()}>
                        Mon compte
                      </p>
                    </div>
                    :
                    ''}
                </div>

                <div className="pe- row" onClick={handleShoppingCartIconClick} ref={blockRef} >
                  <div className="col-12 text-center">
                    <FiShoppingCart className="navbar-icon ms-sm-2" />
                    <span className="position-absolute top-1 start-1s00 translate-middle badge card-badge rounded-circle bg-danger badge-card">
                      {cartItemsQuantity}
                    </span>
                  </div>
                  {
                    windowWidth > 578 ?
                      <div className="col-12 text-center">
                        <p className='ff-0 fw-600 fs-6 c-pointer text-orange-hover' ref={blockRef} onClick={handleShoppingCartIconClick}>
                          Mon panier
                        </p>
                      </div>
                      :
                      ''
                  }
                </div>

              </div>
            </div>
          </div>
        </div>

        {/* Start shoppingCart box */}
        <div ref={elementRef} className={`subshopping-card-box ${ShoppingCartBoxVisible ? 'show' : 'd-nones'}`}>
          <div className="container">
            <div className="row card-content">
              <div className="col-md-12 col-12 mb-3">

                {
                  cartQuantity < 1 ?
                    <>
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center col-">
                          <img src={emptyCart} alt="empty cart" className='subshopping-card-empty-image' />
                        </div>
                        <div className="col-12 mt-3 d-flex justify-content-center align-items-center col-">
                          <h4 className='text-center'>Votre panier est actuellement vide.</h4>
                        </div>
                        <div className="col-12 mt-3 d-flex justify-content-center align-items-center col-">
                          <Link to='/boutique' className='nav-link back-to-boutique mb-3'>RETOUR À LA BOUTIQUE</Link>
                        </div>
                      </div>
                    </>
                    :
                    cartItems.map(item => (
                      <SubShoppingCart key={item.product} {...item} />
                    ))
                }

              </div>
            </div>

            {cartQuantity > 0 && (
              <div className="row">
                <div className="col-md-12 col-12">
                  <div className="total-price text-center d-flex justify-content-between px-4">
                    <span className="price-text mt-2">SOUS-TOTAL :</span>
                    <span className="price-value mt-2">{total.toFixed(2)} DH</span>
                  </div>

                  <div className="col-md-12 col-12 text-center d-none">
                    <button className='command-btn'>COMMANDER</button>
                  </div>

                </div>
              </div>
            )}

          </div>
          <div className="row">
            {cartQuantity > 0 && (
              <>
                {/* <div className="d-none mt-4 py-10px col-md-12 col-12 clr-light bg-blue text-center d-flex justify-content-center align-items-center">
                  <span className=''>
                    Livraison grauite à partir de 300 DH.
                  </span>
                </div> */}

                <div className="col-md-12 col-12 mt-4 text-center card-checkout d-flex justify-content-center align-items-center">
                  <Link to='/my-cart' className='card-checkout-btn mb-2 nav-link'>
                    <MdShoppingCartCheckout className='card-checkout-icon' />VOIR LE PANIER
                  </Link>
                </div>
              </>
            )}
            {/* <div className="subshopping-card-message text-center d-none">
              Livraison grauite à partir de 300 DH.
            </div> */}
          </div>
        </div>
        {/* End shoppingCart box */}

        {/* Start Search box */}
        <div className={`search-box ${searchBoxVisible ? 'show d-block' : 'd-none'}`}>
          <div className="row">
            <div className="col-12 d-flex justify-content-center flex-wrap">
              {
                products.length > 0
                  ?
                  products.map((item) => (
                    <ProductCard key={item.id} item={item} openModal={openModal} productIdsObj={{ productIds, isProductIds }} />
                  ))
                  :
                  textValue === ''
                    ?
                    ''
                    :
                    <>
                      <div className="col-12 d-flex justify-content-center align-items-center col-">
                        <img src={emptyBox} alt="empty box" className='shopping-card-empty-box' />
                      </div>
                      <div className="col-12 d-flex justify-content-center align-items-center col-">
                        <div className="clr-dark fw-600 ff-0 fs-4 mn-13">Aucun Produits</div>
                      </div>
                    </>
              }
            </div>
          </div>
        </div>
        {/* End Search box */}

      </header>
      {showModal && <Modal show={showModal} onClose={closeModal} product={selectedProduct} />}

    </>
  );
};

export default Navbar;