import React, { useContext } from "react";
import './HomeCarouselStyle.css';
import { Carousel } from "react-responsive-carousel";
import { sliderImgUrl } from "../../../helpers/Helpers";
import { WindowWidthContext } from "../../../contexts/WindowWidthProvider";

interface SliderProps {
  isLoading: boolean,
  items: {
    id?: number;
    title?: string;
    image?: string;
    mobileImage?: string;
  }[];
}

const HomeCarousel: React.FC<SliderProps> = ({ items, isLoading }) => {
  const { windowWidth } = useContext(WindowWidthContext)

  return (
    <>
      {
        !isLoading ?
          <div className="slider-container">
            {
              items.length > 0 && <Carousel
                infiniteLoop
                autoPlay
                interval={5000}
                showIndicators
                showThumbs={false}
                transitionTime={600}
                emulateTouch
                swipeScrollTolerance={100}
                verticalSwipe={"natural"}
                preventMovementUntilSwipeScrollTolerance={true}
                showStatus
                showArrows={true}
                renderIndicator={(clickHandler, isSelected, index) => (
                  <div
                    className={`my-indicator ${isSelected ? 'selected' : ''}`}
                    onClick={clickHandler}
                    key={index}
                  >
                    {`0${index + 1}`}
                  </div>
                )}
              >
                {items.map((item) => (
                  <div key={item.title} className="sliderImg">
                    <img alt={item.title} src={`${sliderImgUrl}/${windowWidth > 768 ? item.image : item.mobileImage}`} className="sliderImgg" />
                  </div>
                ))}
              </Carousel>}
          </div>
          : null
      }
    </>
  )
};

export default HomeCarousel;



/*

import React from "react";
import { Carousel } from "react-responsive-carousel";
import './sliderStyle.css';

interface SliderProps {
  items: { title: string; url: string }[];
}

const HomeCarousel: React.FC<SliderProps> = ({ items }) => (
  <div className="slider-container">
    <Carousel
      infiniteLoop
      autoPlay
      interval={5000}
      showIndicators
      showThumbs={false}
      transitionTime={600}
      emulateTouch
      showStatus
      showArrows={true}
      renderIndicator={(clickHandler, isSelected, index) => (
        <div
          className={`my-indicator ${isSelected ? 'selected' : ''}`}
          onClick={clickHandler}
          key={index}
        >
          {`0${index + 1}`}
        </div>
      )}
    >
      {items.map((item) => (
        <div key={item.title} className="sliderImg">
          <img alt="" src={item.url} className="sliderImgg" />
        </div>
      ))}
    </Carousel>
  </div>
);

export default HomeCarousel;

*/
