import React, { createContext, useContext, useState,  Dispatch, SetStateAction } from 'react';

interface OrderRefContextProps {
    orderRef: string;
    setOrderRef: Dispatch<SetStateAction<string>>;
}

const OrderRefContext = createContext<OrderRefContextProps>({
    orderRef: '',
    setOrderRef: () => {},
});

export const useOrderRefContext = () => useContext(OrderRefContext);

export const OrderRefProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [orderRef, setOrderRef] = useState<string>('');

    return (
        <OrderRefContext.Provider value={{ orderRef, setOrderRef }}>
            {children}
        </OrderRefContext.Provider>
    );
};
