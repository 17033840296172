import React from "react";
import './ProductSliderStyle.css';
import { Carousel } from "react-responsive-carousel";

interface SliderProps {
  items: {
    title: string,
    image: string,
  }[];
}

const ProductSlider: React.FC<SliderProps> = ({ items }) => (
  <div className="product-slider">
    <div className="slider-container">
      <Carousel
        infiniteLoop
        autoPlay={false}
        interval={5000}
        showIndicators
        showThumbs={false}
        transitionTime={600}
        emulateTouch
        showStatus
        showArrows={true}
        renderIndicator={(clickHandler, isSelected, index) => (
          <div
            className={`my-indicator ${isSelected ? 'selected' : ''}`}
            onClick={clickHandler}
            key={index}
          >
            {`0${index + 1}`}
          </div>
        )}
      >
        {
          items.map((item, index) => {

            return <div key={index} className="p-sliderImg">
              <img alt={item.title} src={`${process.env.REACT_APP_API_UPLOAD}/product_multi_images/${item.image}`} className="sliderImgg" />
            </div>
          })}
      </Carousel>
    </div>
  </div>
);

export default ProductSlider;
