import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import * as yup from "yup";
import { useFormik } from "formik";
import InputBox from '../../components/InputBox/InputBox';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import DottedLoading from '../../components/Loading/DottedLoading';
import useApiRequest from '@mbs-dev/api-request';
import { ErrorNotify, notify } from '../../helpers/Toastify';
import { Helmet } from 'react-helmet-async';
import './loginStyle.css'
import { apiUrl } from '../../helpers/ApiRequest';

const ForgotPassword: React.FC = (props) => {

  // Global
  const navigate = useNavigate();
  const { apiRequest } = useApiRequest();
  const user_token = localStorage.getItem('hb_user_token');
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    if (user_token) {
      navigate('/my-account');
    }
  }, [user_token]);

  const validationSchema = yup.object({
    email: yup.string().email("Format d'e-mail non valide. Veuillez entrer une adresse e-mail valide.")
      .required("Veuillez saisir votre email"),
  });


  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const formData = new FormData();
        formData.append('email', values.email);

        const response = await apiRequest({
          route: `${apiUrl}/forgot-password`,
          method: 'POST',
          data: formData,
          requiresAuth: true,
          token: `${localStorage.getItem('hb_user_token')}`
        });

        if (response.status === 200) {
          setIsLoading(false)
          if (response.data.status === true) {
            notify(response.data.message);
          }
          else if (response.data.status === false) {
            ErrorNotify(response.data.message);
          }
        }

      } catch (error) {
        // //console.error(error);
        ErrorNotify("Aucun utilisateur trouvé avec l'adresse e-mail!");
      }
    },
  });


  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  return (
    <>
      <Helmet>
        <title>Mot de passe oublié</title>
      </Helmet>
      <TopHeader />
      <Navbar />
      <HeaderContainer
        className="fw-bold fs-1 mt-5"
        title='Mot de passe oublié'
      />
      <div className="container d-flex justify-content-center align-items-center pt-5">

        <form onSubmit={handleSubmit} className='d-flex justify-content-center align-items-center'>
          <div className="login-form">
            <div className="row d-flex justify-content-start align-items-center">
              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-2">
                <InputBox
                  label="Email"
                  spanValue="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  className={touched.email && errors.email ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <Link to='/register' className='nav-link clr-dark ff-0 fw-600 blue-hover'>Vous n'avez pas de compte ? Signup</Link>
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-5 d-flex justify-content-start align-items-center">
                {
                  isLoading ?
                    <DottedLoading />
                    :
                    <SubmitButton
                      className="mt-1 px-5"
                      btnLabel="Envoyé"
                    />
                }

              </div>

            </div>

          </div>
        </form>

      </div>
    </>
  );
};

export default ForgotPassword;