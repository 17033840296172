import React, { useCallback, useEffect, useState } from 'react';
import * as yup from "yup";
import { useFormik } from "formik";
import { UserDashboard } from '../UserDashboard/UserDashboard';
import InputBox from '../../../../components/InputBox/InputBox';
import SubmitButton from '../../../../components/SubmitButton/SubmitButton';
import { apiUrl, logOut } from '../../../../helpers/ApiRequest';
import { UpdateNotify, FrCustomeErrorNorify } from '../../../../helpers/Toastify';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { AuthUserProps } from '../../../../helpers/Props';
import DottedLoading from '../../../../components/Loading/DottedLoading';
import './EditAccountStyle.css'
import useApiRequest from '@mbs-dev/api-request';


const Content: React.FC = () => {

    const { apiRequest } = useApiRequest()
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [isLoadingPass, setIsLoadingPass] = useState(false);
    const navigate = useNavigate();
    const [user, setUser] = useState<AuthUserProps | null>(null);

    useEffect(() => {
        const storedUser = localStorage.getItem('hb_user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);


    const validationSchema = yup.object({
        nom: yup.string().required("Veuillez saisir votre nom"),
        prenom: yup.string().required("Veuillez saisir votre prenom"),
        telephone: yup.string().required("Veuillez saisir votre numéro de telephone"),
    });

    const ResetSchema = yup.object({
        password: yup.string().required("Veuillez saisir votre mot de passe actuel"),
        newpassword: yup.string().required("Veuillez saisir votre mot de passe")
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: yup.string().required('Veuillez confirmer votre mot de passe')
            .oneOf([yup.ref('newpassword'), ''], 'Veuillez confirmer votre mot de passe'),
    });

    const fetchData = useCallback(async () => {
        try {
            if (user?.id) {
                const response = await apiRequest({
                    route: `${apiUrl}/user/id/${user.id}`,
                    method: 'GET',
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`

                });

                if (response.status === 200) {
                    await setValues({
                        nom: response.data.nom ? response.data.nom : '',
                        prenom: response.data.prenom ? response.data.prenom : '',
                        telephone: response.data.telephone ? response.data.telephone : '',
                    });
                }
            }
        } catch (error) {

        }
    }, [user?.id]);

    useEffect(() => {
        fetchData()
    }, [user?.id])

    const formik = useFormik({
        initialValues: {
            nom: "",
            prenom: "",
            telephone: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            if (user?.id) {
                const fullName = `${values.nom} ${values.prenom}`
                const data = {
                    ...values, fullName: fullName
                }
                setIsLoadingInfo(true);
                const response = await apiRequest({
                    route: `${apiUrl}/users/${user?.id}`,
                    method: 'PUT',
                    data: data,
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`
                });
                if (response.status === 200) {
                    UpdateNotify('Votre compte est');
                    setIsLoadingInfo(false)
                } else {
                    FrCustomeErrorNorify()
                    setIsLoadingInfo(false)
                }
            }
        },
    });

    const formikReset = useFormik({
        initialValues: {
            password: "",
            newpassword: "",
            confirmPassword: "",
        },
        validationSchema: ResetSchema,
        onSubmit: async (values) => {
            setIsLoadingPass(true);

            try {
                const formData = new FormData();
                formData.append('password', values.password);
                formData.append('newpassword', values.newpassword);
                formData.append('email', String(user?.email));

                const response = await apiRequest({
                    route: `${apiUrl}/edit-password`,
                    method: 'POST',
                    data: formData,
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`
                });

                if (response.status === 200) {
                    logOut();
                    navigate('/login')
                    UpdateNotify('Mot de passe est')
                    setIsLoadingPass(false)
                } else {
                    FrCustomeErrorNorify()
                    setIsLoadingInfo(false)
                }

            } catch (error) {
                FrCustomeErrorNorify()
                setIsLoadingInfo(false)
            }
        },
    });

    const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        setValues
    } = formik;

    return (
        <>
            <Helmet>
                <title>Détails du compte</title>
            </Helmet>
            <div className="col-12 col-lg-7 col-md-7 col-xl-8 col-xxl-9 hb-ms-4">
                <div className="container contact-container d-flex justify-content-center align-items-center margin-left">

                    <div className="edit-form">
                        <div className="row d-flex justify-content-start align-items-center">
                            <form onSubmit={handleSubmit} className='d-nonex'>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <InputBox
                                        label="Nom *"
                                        spanValue="Nom"
                                        name="nom"
                                        value={values.nom}
                                        className={touched.nom && errors.nom ? "is-invalid" : ""}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <InputBox
                                        label="Prenom *"
                                        spanValue="Prenom"
                                        name="prenom"
                                        value={values.prenom}
                                        className={touched.prenom && errors.prenom ? "is-invalid" : ""}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <InputBox
                                        label="Telephone *"
                                        spanValue="Telephone"
                                        name="telephone"
                                        value={values.telephone}
                                        className={touched.telephone && errors.telephone ? "is-invalid" : ""}
                                        touched={touched}
                                        errors={errors}
                                        handleChange={handleChange}
                                        handleBlur={handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mt-5 d-flex justify-content-start align-items-center">

                                    {
                                        isLoadingInfo ?
                                            <DottedLoading />
                                            :
                                            <SubmitButton
                                                className="px-3"
                                                btnLabel="Enregistrer les modifications"
                                            />
                                    }
                                </div>
                            </form>

                            <form onSubmit={formikReset.handleSubmit}>
                                <hr className='hr-tag mt-4' />

                                <h4 className='text-start mb-4 mt-2' >CHANGEMENT DE MOT DE PASSE</h4>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <InputBox
                                        label="Mot de passe actuel"
                                        spanValue="Mot de passe actuel"
                                        name="password"
                                        type="password"
                                        value={formikReset.values.password}
                                        className={formikReset.touched.password && formikReset.errors.password ? "is-invalid" : ""}
                                        touched={formikReset.touched}
                                        errors={formikReset.errors}
                                        handleChange={formikReset.handleChange}
                                        handleBlur={formikReset.handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <InputBox
                                        label="Nouveau mot de passe"
                                        spanValue="Nouveau mot de passe"
                                        name="newpassword"
                                        type="password"
                                        value={formikReset.values.newpassword}
                                        className={formikReset.touched.newpassword && formikReset.errors.newpassword ? "is-invalid" : ""}
                                        touched={formikReset.touched}
                                        errors={formikReset.errors}
                                        handleChange={formikReset.handleChange}
                                        handleBlur={formikReset.handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mb-4">
                                    <InputBox
                                        label="Confirmer le nouveau mot de passe"
                                        spanValue="Confirmer le nouveau mot de passe"
                                        name="confirmPassword"
                                        type="password"
                                        value={formikReset.values.confirmPassword}
                                        className={formikReset.touched.confirmPassword && formikReset.errors.confirmPassword ? "is-invalid" : ""}
                                        touched={formikReset.touched}
                                        errors={formikReset.errors}
                                        handleChange={formikReset.handleChange}
                                        handleBlur={formikReset.handleBlur}
                                    />
                                </div>

                                <div className="col-12 col-md-12 col-lg-12 mt-5 d-flex justify-content-start align-items-center">

                                    {
                                        isLoadingPass ?
                                            <DottedLoading />
                                            :
                                            <SubmitButton
                                                className="px-3"
                                                btnLabel="Modifier le mot de passe"
                                            />
                                    }
                                </div>
                            </form>

                        </div>

                    </div>

                </div>
            </div>
        </>
    );
};

const EditAccount: React.FC = (props) => {

    return (
        <>
            <UserDashboard secondComponent={Content} />
        </>
    );
};

export { EditAccount };