import useApiRequest from '@mbs-dev/api-request';
import { useState, useEffect } from 'react';
import { apiUrl } from '../helpers/ApiRequest';

export const useProductIds = (): { productIds: number[]; isProductIds: boolean } => {
  const [productIds, setProductIds] = useState<number[]>([]);
  const { apiRequest } = useApiRequest();
  const [isProductIds, setIsProductIds] = useState(false);

  const fetchProductIds = async () => {
    try {
      const response = await apiRequest({
        route: `${apiUrl}/products-with-promotions`,
        method: 'GET'
      });

      if (response.status === 200) {
        setProductIds(response.data.productIds);
        setIsProductIds(true);
      }
    } catch (error) {
      //console.error(error);
    }
  };

  useEffect(() => {
    fetchProductIds();
  }, []);

  return { productIds, isProductIds };
};
