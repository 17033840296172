import LoadingData from './LoadingData';
import './loadingDataStyle.css'

interface AnimatedLoadingProps {
    className?: string;
    label?: string;
}

const AnimatedLoading: React.FC<AnimatedLoadingProps> = ({ className, label }) => {

    return (
        <div className={`loading-data animated-loading ${className}`}>
            <span className='loading-data-text text-center clr-dark ff-0 fw-600 fs-5 mt-2'>Chargement {label}...</span>
            <LoadingData />
        </div>


    );
};

export default AnimatedLoading;