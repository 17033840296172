import React, { useState, useEffect, ChangeEvent, useContext, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import TopHeader from '../../components/TopHeader/TopHeader';
import HeaderContainer from '../../components/HeaderContainer/HeaderContainer';
import facebook from '../../../assets/images/login/facebook-icon.png'
import * as yup from "yup";
import { useFormik } from "formik";
import { BsFacebook } from 'react-icons/bs';
import { apiUrl, pendingGoogle } from '../../helpers/ApiRequest';
import { LoginSocialGoogle, IResolveParams } from 'reactjs-social-login'
import { GoogleLoginButton } from 'react-social-login-buttons'
import { ReactFacebookLoginInfo } from 'react-facebook-login';
import ReactFacebookLogin from 'react-facebook-login';
import InputBox from '../../components/InputBox/InputBox';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import axios from 'axios';
import { InfoNotify, ErrorNotify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import DottedLoading from '../../components/Loading/DottedLoading';
import { Helmet } from 'react-helmet-async';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import './loginStyle.css'
import useApiRequest from '@mbs-dev/api-request';


const Register: React.FC = () => {

  // Global
  const location = useLocation();
  const navigate = useNavigate();
  const { apiRequest } = useApiRequest();
  const isLogin = location.pathname === '/login';
  const isRegister = location.pathname === '/register';
  const [isLoading, setIsLoading] = useState(false);
  const [isLoginWithEmail, setIsLoginWithEmail] = useState(false);
  const { windowWidth } = useContext(WindowWidthContext)
  const withGoogleRef = useRef<HTMLButtonElement | null>(null);

  const handleElse = () => {
    FrCustomeErrorNorify()
    setIsLoading(false)
  }
  const startWithGoogle = () => {
    if (withGoogleRef.current) {
      withGoogleRef.current.click();
    }
  };

  // Google Login
  const REACT_APP_GG_APP_ID = '324294602884-jad235obqifn3pm5ecmgnv7h752v89pu.apps.googleusercontent.com';
  const [provider, setGoogleProvider] = useState('')
  const [googleProfile, setGoogleProfile] = useState<any>()
  const [isLoginWithGoogle, setIsLoginWithGoogle] = useState(false);

  // Faebook Login
  const REACT_APP_FB_APP_ID = '3443858605865162';
  const [facebookLogin, setFacebookLogin] = useState(false);
  const [facebookProfile, setFacebookProfile] = useState<ReactFacebookLoginInfo>();
  const [fbClick, setFbClick] = useState(false);
  const [isLoginWithFacebook, setIsLoginWithFacebook] = useState(false);

  const responseFacebook = (response: ReactFacebookLoginInfo) => {
    setFacebookProfile(response);
    if (response.accessToken) {
      setFacebookLogin(true);
    } else {
      setFacebookLogin(false);
    }
  };

  const handleClickFacebook = () => {
    setFbClick(true);
  };


  const validationSchema = yup.object({
    nom: yup.string().required("Veuillez saisir votre nom"),
    prenom: yup.string().required("Veuillez saisir votre prenom"),
    telephone: yup.string().required("Veuillez saisir votre numéro de telephone"),
    email: yup.string().email("Format d'e-mail non valide. Veuillez entrer une adresse e-mail valide.")
      .required("Veuillez saisir votre email"),
    password: yup.string().required("Veuillez saisir votre mot de passe").min(6, 'Password must be at least 6 characters'),
    confirmPassword: yup.string().required('Veuillez confirmer votre mot de passe')
      .oneOf([yup.ref('password'), ''], 'Veuillez confirmer votre mot de passe'),
  });

  const formik = useFormik({
    initialValues: {
      nom: "",
      prenom: "",
      telephone: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      const formData = new FormData();
      formData.append('nom', values.nom);
      formData.append('prenom', values.prenom);
      formData.append('email', values.email);
      formData.append('telephone', values.telephone);
      formData.append('password', values.password);
      formData.append('loginWith', 'email');

      try {
        setIsLoading(true)

        const response = await apiRequest({
          route: `${apiUrl}/user/email/${values.email}`,
          method: 'GET',
        });

        if (response.status === 200) {
          if (response.data === 'Found') {
            ErrorNotify('Email déja exist')
            setIsLoading(false)

          } else {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, formData);

            if (response.status === 200) {
              formData.delete('loginWith')

              const loginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/login`, values, {
                headers: {
                  accept: 'application/json',
                  'Content-Type': 'application/json',
                },
              });

              if (loginResponse.status === 200) {
                const token = loginResponse.data.token;
                localStorage.setItem('hb_user_token', token);
                setIsLoginWithEmail(true)
              } else {
                handleElse()
              }

            } else {
              handleElse()
            }

          }
        } else {
          handleElse()
        }

      } catch (error) {
        handleElse()
      }

    },
  });


  // =============================== Start withGoogle
  const withGoogle = async () => {

    if (googleProfile) {
      setIsLoading(true)

      const GPassword = `${googleProfile.email}${googleProfile.sub}`;
      const formData = new FormData();
      formData.append('email', googleProfile.email);
      formData.append('password', GPassword);
      formData.append('loginWith', 'google');

      const response = await apiRequest({
        route: `${apiUrl}/user/email/${googleProfile.email}`,
        method: 'GET',
      });

      if (response.data === 'Not Found') {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, formData);

          if (response.status === 200) {
            formData.delete('loginWith')

            const loginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/login`, formData, {
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
            });

            const token = loginResponse.data.token;
            localStorage.setItem('hb_user_token', token);
            setIsLoginWithGoogle(true);
            InfoNotify('Merci de compléter les details de votre compte')

          } else {
            handleElse()
          }

        } catch (error) {
          handleElse()
        }

      } else {
        formData.delete('loginWith')

        const loginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/login`, formData, {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

        const token = loginResponse.data.token;
        localStorage.setItem('hb_user_token', token);
        setIsLoginWithGoogle(true);
      }

    }
  }

  useEffect(() => {
    if (googleProfile) {
      withGoogle();
    }
  }, [googleProfile])
  // =============================== End withGoogle


  // =============================== Start withFacebook
  const withFacebook = async () => {

    if (facebookProfile && facebookProfile.userID) {
      setIsLoading(true)

      const GPassword = `${facebookProfile.userID}@withfacebook`;
      const formData = new FormData();
      formData.append('email', facebookProfile.userID);
      formData.append('password', GPassword);
      formData.append('loginWith', 'facebook');

      const response = await apiRequest({
        route: `${apiUrl}/user/email/${facebookProfile.userID}`,
        method: 'GET',
      });

      if (response.data === 'Not Found') {
        try {
          const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, formData);

          if (response.status === 200) {
            formData.delete('loginWith')

            const loginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/login`, formData, {
              headers: {
                accept: 'application/json',
                'Content-Type': 'application/json',
              },
            });

            const token = loginResponse.data.token;
            localStorage.setItem('hb_user_token', token);
            setIsLoginWithFacebook(true);
            InfoNotify('Merci de compléter les details de votre compte')

          } else {
            handleElse()
          }

        } catch (error) {
          handleElse()
        }

      } else {
        formData.delete('loginWith')

        const loginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/login`, formData, {
          headers: {
            accept: 'application/json',
            'Content-Type': 'application/json',
          },
        });

        const token = loginResponse.data.token;
        localStorage.setItem('hb_user_token', token);
        setIsLoginWithFacebook(true);
      }

    } else {
      handleElse()
    }
  }

  useEffect(() => {
    if (facebookProfile && facebookProfile.userID) {
      withFacebook();

    }
  }, [facebookProfile])
  // =============================== End withFacebook



  const getToken = async () => {
    const user_token = localStorage.getItem('hb_user_token');

    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/me`, {
        headers: {
          Authorization: `Bearer ${user_token}`,
        },
      });
      if (response.status === 200) {
        localStorage.setItem('hb_user', JSON.stringify(response.data));
        isLoginWithEmail ? navigate('/my-account') : navigate('/my-account/edit-account');
      }

    } catch (error) {
      handleElse()
    }
  };

  useEffect(() => {
    if (isLoginWithGoogle) {
      getToken();
    }
    if (isLoginWithEmail) {
      getToken();
    }
    if (isLoginWithFacebook) {
      getToken();
    }
  }, [isLoginWithGoogle, isLoginWithEmail, isLoginWithFacebook])

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  // const handleEmailChange = async (mail: string) => {
  //   try {
  //     const response = await apiRequest({
  //       route: `user/email/${mail}`,
  //       method: 'GET',
  //     });

  //     if (response.status === 200) {
  //       setUserEmail(response.data);
  //     }
  //   } catch (error) {

  //   }
  // };


  return (
    <>
      <Helmet>
        <title>S'inscrire</title>
      </Helmet>
      <TopHeader />
      <Navbar />
      <HeaderContainer
        className="fw-bold fs-1 mt-5"
        title={
          <>
            <div className="d-flex text-center justify-content-center">
              <Link className={`nav-link ${isRegister ? 'text-opacity' : ''} ${windowWidth > 578 ? '' : 'fs-2'}`} to="/login">
                Connexion
              </Link>
              <p className="mx-3">/</p>
              <Link className={`nav-link ${isLogin ? 'text-opacity' : ''} ${windowWidth > 578 ? '' : 'fs-2'}`} to="/register">
                S'enregistrer
              </Link>
            </div>
          </>
        }
      />
      <div className={`d-flex-center ${windowWidth > 578 ? 'container pt-5' : 'auth-container-max-w pt-1'}`}>

        <form onSubmit={handleSubmit} className='d-flex-center'>
          <div className="login-form mb-5">
            <div className="row d-flex-center">

              <div className="col-12 col-md-12 col-lg-12 mb-3">
                <InputBox
                  label="Nom *"
                  spanValue="Nom"
                  name="nom"
                  value={values.nom}
                  className={touched.nom && errors.nom ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 mb-3">
                <InputBox
                  label="Prenom *"
                  spanValue="Prenom"
                  name="prenom"
                  value={values.prenom}
                  className={touched.prenom && errors.prenom ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 mb-3">
                <InputBox
                  label="Telephone *"
                  spanValue="Telephone"
                  name="telephone"
                  value={values.telephone}
                  className={touched.telephone && errors.telephone ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                <InputBox
                  label="Email *"
                  spanValue="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  className={touched.email && errors.email ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
                    handleBlur(event);
                  }}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <InputBox
                  label="Mot de passe *"
                  spanValue="Mot de passe"
                  name="password"
                  type="password"
                  value={values.password}
                  className={touched.password && errors.password ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
                    handleChange(event);
                  }}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-3">
                <InputBox
                  label="Confirmer le mot de passe *"
                  spanValue="Confirmer le mot de passe"
                  name="confirmPassword"
                  type="password"
                  value={values.confirmPassword}
                  className={touched.confirmPassword && errors.confirmPassword ? "is-invalid" : ""}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                />
              </div>

              <div className="col-12 col-md-12 col-lg-12 col-xl-12 mt-4 d-flex justify-content-start align-items-center">
                {
                  isLoading ?
                    <div className="mt-3">
                      <DottedLoading />
                    </div>
                    :
                    <SubmitButton
                      className="mt-1 px-5"
                      btnLabel="S'enregistrer"
                    />
                }
              </div>

            </div>

            <hr className='hr-tag' />

            <div className="row d-flex-center max-sm-w mt-4">

              {/* Start Google */}
              <div className="col-12 d-flex-center">

                <div className="with-google d-flex-center">
                  <div className="with-google-logo">

                    <LoginSocialGoogle
                      client_id={REACT_APP_GG_APP_ID || ''}
                      scope="openid profile email"
                      discoveryDocs="claims_supported"
                      access_type="offline"
                      onResolve={async ({ provider, data }: IResolveParams) => {
                        try {
                          setGoogleProvider(provider);
                          setGoogleProfile(data);
                        } catch (error) {

                        }
                      }}
                      onReject={async (err) => {
                        try {

                        } catch (error) {

                        }
                      }}
                    >
                      <span className='hb-btn' ref={withGoogleRef}><GoogleLoginButton className='google-btn login-icon' /></span>
                    </LoginSocialGoogle>
                  </div>

                  <div className="with-google-text ff-0 fw-600 c-pointer" onClick={pendingGoogle}>
                    Continue Avec Google
                  </div>
                </div>

              </div>
              {/* End Google */}

              {/* Start Separate */}
              <div className="col-12 d-flex-center with-social">
                <div className="with-social-sepetaror">
                  <p className='ff-0 fw-600'> <span className='with-social-sepetaror-before'></span> OU <span className='with-social-sepetaror-after'></span></p>
                </div>
              </div>
              {/* End Separate */}


              {/* Start Facebook */}
              <div className="col-12 d-flex-center">

                <div className="with-facebook d-flex-center">
                  <div className="with-facebook-logo">
                    <img src={facebook} className='login-icon ms-1 me-2' alt="facebook" onClick={handleClickFacebook} />
                    {fbClick && !facebookLogin &&
                      <ReactFacebookLogin
                        appId={REACT_APP_FB_APP_ID}
                        autoLoad={true}
                        fields="name,email,picture"
                        scope="public_profile,user_friends"
                        callback={responseFacebook}
                        icon={<BsFacebook />} />
                    }
                  </div>

                  <div className="with-facebook-text ff-0 fw-600 c-pointer" onClick={handleClickFacebook}>
                    Continue Avec Facebook
                  </div>
                </div>

              </div>
              {/* End Facebook */}


            </div>
          </div>
        </form>

      </div>
    </>
  );
};

export default Register;