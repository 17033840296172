import { toast } from 'react-toastify';

export const notify = (text: string, delay: number = 1500) => {
    toast.success(`${text}`, {
        autoClose: delay,
    });
};

export const ErrorNotify = (text: string, delay: number = 4000) => {
    toast.error(`${text}`, {
        autoClose: delay,
    });
};

export const InfoNotify = (text: string, delay: number = 4000) => {
    toast.info(`${text}`, {
        autoClose: delay,
    });
};

export const AddingNotify = (entity: string, delay: number = 1500) => {
    toast.success(`${entity} ajouté avec succès`, {
        autoClose: delay,
    });
};

export const UpdateNotify = (entity: string, delay: number = 4000) => {
    toast.success(`${entity} modifié avec succès`, {
        autoClose: delay,
    });
};

export const DeleteNotify = (entity: string, delay: number = 4000) => {
    toast.success(`${entity} supprimé avec succès`, {
        autoClose: delay,
    });
};

export const EnCustomeErrorNorify = () => {
    toast.error('Something wrong, try again');
};

export const FrCustomeErrorNorify = () => {
    toast.error('Une erreur est survenue, réessayez');
};