import { FC } from 'react';
import { useCategoriesContext } from '../../contexts/CategoriesContext';
import { Link } from 'react-router-dom';
import HeaderContainer from '../HeaderContainer/HeaderContainer';
import './CategoriesStyle.css'

const CategoriesOld: FC = () => {
  const { categories, isFetch } = useCategoriesContext();

  return (
    <>
      {!isFetch ?
        <div className='productss categories'>
          <div className="container product--container">
            <HeaderContainer className="fw-bold fs-1" title="CATÉGORIES" />
            <div className="row">
              {
                categories.map((item) => {
                  return <div className="col-md-6 col-lg-4 col-12 category-content" key={item.title}>
                    <div className="category-details text-center">
                      <Link to={`/boutique/categorie/${item.slug}`} className="category-title nav-link mb-2">{item.title}</Link>
                      <div className="category-img">
                        <Link to={`/boutique/categorie/${item.slug}`}><img src={`${process.env.REACT_APP_API_UPLOAD}/category_images/${item.image}`} alt={item.title} className='img-fluid' /></Link>
                      </div>
                    </div>
                  </div>
                })

              }
            </div>
          </div>
        </div>
        :
        null
      }

    </>
  );
};

export default CategoriesOld;