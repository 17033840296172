import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import './MarqueSwiper.css'
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Link } from "react-router-dom";
import { MarqueProps } from "../../../helpers/Props";
import { useContext } from "react";
import { handleImageLinkDrage, marqueImgUrl } from '../../../helpers/ApiRequest';
import { WindowWidthContext } from '../../../contexts/WindowWidthProvider';

interface MarqueSwiperProps {
    marques: MarqueProps[];

}

const MarqueSwiper: React.FC<MarqueSwiperProps> = ({ marques }) => {
    const { windowWidth } = useContext(WindowWidthContext)

    let screen = 9

    if (windowWidth < 1600 && windowWidth >= 1430) {
        screen = 9
    } else if (windowWidth < 1430 && windowWidth >= 1300) {
        screen = 7
    } else if (windowWidth < 1300 && windowWidth >= 1200) {
        screen = 8
    } else if (windowWidth < 1200 && windowWidth >= 992) {
        screen = 7
    } else if (windowWidth < 992 && windowWidth >= 768) {
        screen = 6
    } else if (windowWidth < 768 && windowWidth >= 578) {
        screen = 5
    } else if (windowWidth < 578 && windowWidth >= 500) {
        screen = 4
    } else if (windowWidth < 500) {
        screen = 3
    }

    return (
        <div className='boutique mb-5 mt-3'>
            <div className="container">
                <div className="row justify-content-center marque-navigation">
                    <Swiper
                        className="mySwiper"
                        loop={true}
                        centeredSlides
                        slidesPerView={screen}
                        // rewind
                        modules={[Autoplay, Pagination, Navigation]}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={false}
                        // navigation
                        
                        
                    >
                        {marques.map((item: MarqueProps, index) => (
                            <SwiperSlide key={item.id}>
                                <div className="marque-swiper-content d-flex align-marques-center justify-content-center mxc-5" key={item.id}>
                                    <div className="marque-swiper d-flex-center">
                                        <Link to={`/boutique/marque/${item.slug}`} onDragStart={handleImageLinkDrage} className='nav-link w-100 h-100'>
                                            <img
                                                src={`${marqueImgUrl}/${item?.image}`}
                                                className="marque-swiper-img"
                                                alt={item?.title}
                                                onDragStart={handleImageLinkDrage}
                                            />
                                        </Link>

                                    </div>

                                    <div className="d-none marque-swiper-title mt-3 d-non align-marques-center justify-content-center">
                                        <p className='marque-swiper-title ff-0 fw-600'>{item?.title}</p>
                                    </div>

                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </div>
    );
};

export default MarqueSwiper;