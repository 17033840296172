import React, { useContext, useEffect, useState } from 'react';
import { ReviewItem } from './ReviewItem';
import { Rating } from 'react-simple-star-rating';
import InputBox from '../InputBox/InputBox';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useFormik } from 'formik';
import * as yup from "yup";
import { AuthUserProps, ReviewsProps } from '../../helpers/Props';
import { FormatDate, apiUrl } from '../../helpers/ApiRequest';
import { InfoNotify, ErrorNotify, notify, FrCustomeErrorNorify } from '../../helpers/Toastify';
import { useNavigate } from 'react-router-dom';
import DottedLoading from '../Loading/DottedLoading';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import './ReviewsStyle.css'
import useApiRequest from '@mbs-dev/api-request';

interface ReviewFormProps {
  productId?: number;
  isPack?: boolean;
}

const ReviewForm: React.FC<ReviewFormProps> = ({ productId, isPack }) => {

  const [authUser, setAuthUser] = useState<AuthUserProps | null>(null);
  const storedUser = localStorage.getItem('hb_user');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { windowWidth } = useContext(WindowWidthContext)

  useEffect(() => {
    if (storedUser) {
      setAuthUser(JSON.parse(storedUser));
    }
  }, []);

  const [productReviews, setProductReviews] = useState<ReviewsProps[]>([]);
  const [ReviewsCountCheck, setReviewsCountCheck] = useState(false)
  const [ReviewsCount, setReviewsCount] = useState(0)
  const { apiRequest } = useApiRequest();


  const status = 'pending';
  const product = `api/products/${productId}`;
  const pack = `api/packs/${productId}`;
  const user = `api/users/${authUser?.id}`;

  const validationSchema = yup.object({
    message: yup.string().required("Veuillez saisir votre avis"),
    rating: yup.number().required("Veuillez donner une note"),
  });

  const formik = useFormik({
    initialValues: {
      message: "",
      rating: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data;

      isPack
        ? data = { ...values, pack, user, dateCreate: FormatDate(new Date()), status }
        : data = { ...values, product, user, dateCreate: FormatDate(new Date()), status }

      try {

        if (authUser?.id) {
          setIsLoading(true)
          const route = isPack
            ? `${apiUrl}/check-pack-reviews/pack/${productId}/user/${authUser?.id}`
            : `${apiUrl}/check-product-reviews/product/${productId}/user/${authUser?.id}`

          const response = await apiRequest({
            route: route,
            method: 'GET',
            requiresAuth: true,
            token: `${localStorage.getItem('hb_user_token')}`
          });

          if (response.status === 200) {
            if (response.data.message === 'Not Found') {
              const response = await apiRequest({
                route: `${apiUrl}/user/check-user-info/${authUser?.id}`,
                method: 'GET',
                requiresAuth: true,
                token: `${localStorage.getItem('hb_user_token')}`
              });

              if (response.status === 200) {
                if (response.data.result === 'true') {
                  const response = await apiRequest({
                    route: `${apiUrl}/reviews`,
                    method: 'POST',
                    data: data,
                    requiresAuth: true,
                    token: `${localStorage.getItem('hb_user_token')}`
                  });

                  if (response.status === 201) {
                    notify('Avis bien envoyé');
                    setIsLoading(false)
                  }
                }
                else {
                  navigate('/my-account/edit-account')
                  InfoNotify('Vous devez ajouter vos informations de compte')
                }
              }

            } else {
              ErrorNotify('Vous avez déjà envoyé votre avis')
              setIsLoading(false)
            }
          }
        } else {
          InfoNotify('Vous devrez être connecté pour effectuer cette action!');
        }

      } catch (error) {
        FrCustomeErrorNorify()
        setIsLoading(false)
      }


    },
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
  } = formik;

  const route = isPack
    ? `${apiUrl}/reviews/pack_id/${productId}`
    : `${apiUrl}/reviews/product_id/${productId}`

  const fetchReviews = async () => {
    try {
      const response = await apiRequest({
        route: route,
        method: 'GET'
      });

      if (response.status === 200) {
        setProductReviews(response.data.reviews);
        if (response.data.reviews.length > 0) {
          setReviewsCount(response.data.reviews.length);
          setReviewsCountCheck(true);
        }
      } else {
        FrCustomeErrorNorify()
      }

    } catch (error) {
      // FrCustomeErrorNorify()
    }
  };


  useEffect(() => {
    fetchReviews()
  }, [])


  return (
    <div className={`review-form ${windowWidth > 992 ? '' : 'mt-5'}`}>
      <div className="row">
        {

          ReviewsCountCheck === true ?
            <>
              <h2 className="text-center text-blue reviews-title fw-bold mb-5 mt-2">Avis ({ReviewsCount})</h2>
              {
                productReviews.map(review => (
                  <ReviewItem key={review.id}
                    name={`${review.user.nom} ${review.user.prenom}`}
                    comment={review.message}
                    rating={review.rating}
                  />
                ))
              }
            </>
            :
            ''
        }

      </div>

      <div className="row">
        <div className="col-12"></div>
        <form onSubmit={handleSubmit}>
          <div className="col-12">
            <h3 className="text-start text-blue fw-bold mb-5 mt-">Ajouter un Avis</h3>
          </div>

          <div className="col-12 mb-4">
            <p>Votre Note *</p>
            <div className="rating">
              <Rating
                onClick={(rate) =>
                  handleChange({ target: { name: 'rating', value: rate } })
                }
                initialValue={0}
              />
              {touched.rating && errors.rating && (
                <div className="error-message">{errors.rating}</div>
              )}
            </div>
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-12 mb-4">
            <InputBox
              label="Votre avis *"
              spanValue="Votre avis"
              name="message"
              textarea={true}
              rows={6}
              value={values.message}
              className={touched.message && errors.message ? 'is-invalid' : ''}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>

          <div className="col-12 col-md-12 col-lg-12 col-xl-12 review-box-btn mt-2">
            {
              isLoading ?
                <DottedLoading />
                :
                <SubmitButton btnLabel="Soumettre" className="mt-1 mb-5" />
            }
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReviewForm;