import { FC } from 'react';
import './Error404.css';
import error from '../../../assets/images/error/404.jpg';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Error404: FC = () => {

    const navigate = useNavigate();

    const home = () => {
        navigate('/')
    }
    return (
        <>
            <Helmet>
                <title>404 - Page Not Found</title>
            </Helmet>
            <div className="error-container">
                <div className="error-logo d-flex justify-content-center align-items-center h-100">
                    <img src={error} alt="" className='error-img' />
                </div>
                <div className="text-center text-light">
                    <button className="btn-error mt-5" onClick={home} >
                        retour à la page d'accueil
                    </button>
                </div>
            </div>
        </>
    );
}

export default Error404