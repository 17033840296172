import { FC, useContext, useEffect, useState } from 'react';
import cmi from '../../../assets/images/footer/cmi.png'
import visa from '../../../assets/images/footer/verified_by_visa.png'
import master from '../../../assets/images/footer/securecode.png'
import { Link } from 'react-router-dom';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import './FooterStyle.css'

const Footer: FC = () => {
  const [showFooter, setShowFooter] = useState(false);
  const { windowWidth } = useContext(WindowWidthContext)

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.innerHeight + window.pageYOffset;
      const pageHeight = document.documentElement.scrollHeight;

      if (scrollPosition >= pageHeight - 20) {
        setShowFooter(true);
      } else {
        setShowFooter(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showFooter && (
        <footer className={`footer fixedbottom ${windowWidth > 810 ? '' : 'pt-4'}`}>
          <div className="fixed-bottom footer-container d-flex flex-column align-items-center justify-content-center">
            <div className="container">
              <div className="row d-flex-center w-100">

                <div className="col-12 text-center d-flex-center">
                  <div className={`condition pt-5 ${windowWidth > 810 ? 'pb-5' : 'pb-4 pt-3'}`}>
                    <Link className='nav-link terms-utl condition-util pe-5s' target='_blank' to='/conditions-generales-dutilisation'>Conditions générales d'utilisation</Link>
                    <span>|</span>
                    <Link className='nav-link terms-utl ps-5s' target='_blank' to='/conditions-generales-de-vente' >Conditions Générales de Vente</Link>
                  </div>
                </div>

                <div className="col-12 text-center d-flex-center">
                  <span className='copyright ff-0 fw-600'>HBeauty - Votre Atout Beauté.</span>
                </div>

              </div>
            </div>
            <hr className='hr-tag' />
            <div className="copyright-container w-100 d-flex-center">
              <div className={`row d-flex-between-center copyright-row w-80 pt-2 ${windowWidth > 810 ? 'pb-1' : 'pb-5'}`}>
                <div className="col-lg-6 col-12 text-center pt-3">
                  <p className='ff-0 fw-600 p-0'>HBeauty &copy; Tous droits réservés</p>
                </div>
                <div className={`col-lg-6 col-12 text-center ${windowWidth > 810 ? '' : 'pb-4'}`}>
                  <div className="footer-images d-flex-center">
                    <img src={cmi} alt="cmi" className={`h-100 px-2 ${windowWidth > 810 ? '' : 'px-3'}`} />
                    <img src={master} alt="cmi" className={`h-100 px-2 ${windowWidth > 810 ? '' : 'px-3'}`} />
                    <img src={visa} alt="cmi" className={`h-100 px-2 ${windowWidth > 810 ? '' : 'px-3'}`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default Footer;