import ReactLoading from "react-loading";
import './loadingStyle.css'

const Loading: React.FC = () => {

    return (
        <>
            <div className="d-flex justify-content-center align-items-center loader-parent" style={{ height: '100vh' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <ReactLoading type='spinningBubbles' color='var(--light)' height={130} width={90} />
                        </div>
                        <div className="col-12 d-flex justify-content-center align-items-center">
                            <h2 className="text-light loading-text">Chargement...</h2>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Loading;