import React, { useState, memo, useEffect, useContext } from 'react';
import AnimatedLoading from '../../components/Loading/AnimatedLoading';
import TopHeader from '../../components/TopHeader/TopHeader';
import Navbar from '../../components/Navbar/Navbar';
import ProductImagesGallery from '../../components/Carousel/ProductImagesGallery/ProductImagesGallery';
import Footer from '../../components/Footer/Footer';
import { FiShoppingCart } from 'react-icons/fi';
import { HiMinusSm } from 'react-icons/hi';
import { BiPlus } from 'react-icons/bi';
import { useLocation, useParams } from 'react-router-dom';
import SubmitButton from '../../components/SubmitButton/SubmitButton';
import ShareButtons from '../../components/ShareButtons/ShareButtons';
import ReviewForm from '../../components/Reviews/ReviewForm';
import { Helmet } from 'react-helmet-async';
import { useShoppingCart } from '../../contexts/ShoppingCartContext';
import useApiRequest from '@mbs-dev/api-request';
import { ProductProps } from '../../helpers/Props';
import { Breadcrumbs } from '@mui/material';
import { emphasize, styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { AiFillHome } from 'react-icons/ai'
import { FrCustomeErrorNorify } from '../../helpers/Toastify';
import { WindowWidthContext } from '../../contexts/WindowWidthProvider';
import './ProductDetailsStyle.css'
import ProductImageThumbnails from '../../components/Carousel/ProductImageThumbnails/ProductImageThumbnails';
import { apiUrl } from '../../helpers/ApiRequest';

const ProductDetails: React.FC = () => {

    const { slug } = useParams();
    const [cardItemQuantity, setCardItemQuantity] = useState(1);
    const { addToCart } = useShoppingCart()
    const { apiRequest } = useApiRequest();
    const [discount, setDiscount] = useState(null);
    const [product, setProduct] = useState<ProductProps>();
    const { windowWidth } = useContext(WindowWidthContext)
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation()
    const [discountPrice, setDiscountPrice] = useState<number | null>(null);


    const fetchProductData = async () => {
        try {
            setIsLoading(true)
            const response = await apiRequest({
                route: `${apiUrl}/product-slug/${slug}`,
                method: 'GET',
            });

            if (response.status === 200) {
                setProduct(response.data)
                setIsLoading(false)
            } else {
                FrCustomeErrorNorify()
                setIsLoading(false)
            }

        } catch (error) {
            FrCustomeErrorNorify()
            setIsLoading(false)
        }
    };

    const fetchData = async () => {
        try {

            if (product?.id) {
                const response = await apiRequest({
                    route: `${apiUrl}/promotion-discount/product_id/${product?.id}`,
                    method: 'GET',
                });

                if (response.status === 200) {
                    response.data.discount !== null ? setDiscount(response.data.discount) : setDiscountPrice(response.data.price);
                    // setDiscount(response.data.discount)
                    // response.data.discount !== null ?? setDiscount(response.data.discount);
                    // response.data.price !== null ?? setDiscountPrice(response.data.price);
                } else {
                    FrCustomeErrorNorify()
                }
            }

        } catch (error) {

        }
    }


    // useEffect(() => {
    //     fetchProductData();
    // }, [])

    useEffect(() => {
        if (state) {
            setProduct(state)
        } else {
            fetchProductData()
        }
    }, [])


    useEffect(() => {
        fetchData();
    }, [product?.id])


    const increment = () => {
        setCardItemQuantity(prevItem => prevItem + 1);
    };

    const decrement = () => {
        if (cardItemQuantity > 1) {
            setCardItemQuantity(prevItem => prevItem - 1);
        }
    };


    if (!product) {
        return null;
    }

    // let productPrice = discount !== null ? (product.tarif_regulier - (product.tarif_regulier * (discount / 100))) : product.tarif_regulier;

    let productPrice = discount !== null ?
        product.tarif_regulier - (product.tarif_regulier * (discount / 100))
        : discountPrice !== null ? discountPrice
            : product.tarif_regulier;

    const StyledBreadcrumb = styled(Chip)(({ theme }) => {
        const backgroundColor =
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[800];
        return {
            backgroundColor,
            height: theme.spacing(3),
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.06),
            },
            '&:active': {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
            },
        };
    }) as typeof Chip;

    return (

        <>
            <Helmet>
                <title>HBeauty - {product.title}</title>
            </Helmet>
            <TopHeader />
            <Navbar />

            {
                isLoading ?
                    <div className="product-details px-5 m-3">
                        <AnimatedLoading label='les details de produit' className='col-12' />
                    </div>
                    :
                    <React.Fragment>
                        <div className="product-details mt-5 pt-5 mb-5">
                            <div className="product-constainesr container d-flexd justify-content-cdenter ">
                                <div className="row product-details-content d-flex justify-content-around">

                                    <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-12 mt-5 mb-5">
                                        <div className={`product-image-gallery ${product.productImages.length < 1 ? 'd-flex justify-content-center' : ''}`}>
                                            {
                                                product.productImages.length > 0 ?
                                                    // <ProductImageThumbnails productImges={product.productImages} />
                                                    <ProductImagesGallery product={product} />
                                                    :
                                                    <img src={`${process.env.REACT_APP_API_UPLOAD}/product_images/${product.image}`}
                                                        alt={product.title}
                                                        className="img-fluid product-details-image d-flex justify-content-center" />
                                            }
                                        </div>
                                        {product.video_url && (
                                            <div className="product-video mt-5 d-non">
                                                <div className="video-container">
                                                    <iframe
                                                        id="player"
                                                        src={product.video_url.replace('watch?v=', 'embed/')}
                                                        allowFullScreen
                                                        title={product.title}
                                                    ></iframe>
                                                </div>
                                            </div>
                                        )}

                                    </div>

                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mt-2 ps-3 ps-sm-1">


                                        <Breadcrumbs aria-label="breadcrumb" className=''>
                                            <StyledBreadcrumb
                                                component="a"
                                                href="/"
                                                label="Accueil"
                                                icon={<AiFillHome fontSize="small" />}
                                            />
                                            <StyledBreadcrumb
                                                component="a"
                                                href={`/boutique/categorie/${product.categorie[0].slug}`}
                                                label={`${product.categorie[0].title}`}
                                            />
                                            {
                                                product.categorie[1] ?
                                                    <StyledBreadcrumb
                                                        component="a"
                                                        href={`/boutique/categorie/${product.categorie[0].slug}/${product.categorie[1]?.slug}`}
                                                        label={`${product.categorie[1]?.title}`}
                                                    />
                                                    :
                                                    ''
                                            }


                                            {/* {product.categorie.length > 1 ? ( */}

                                            {/* ) : ''} */}
                                        </Breadcrumbs>

                                        <div className="product-details-info">
                                            <h1 className='product-details-title'>{product.title}</h1>
                                            {/* <p className='product-details-prix mt-3 mb-5'>{productPrice} DH</p> */}
                                            {productPrice !== product.tarif_regulier
                                                ?
                                                <>
                                                    <del className='me-2 discount-price mt-3'>{product.tarif_regulier}DH</del>
                                                    <p className="product-details-prix mt-1 mb-5 clr-orange">{productPrice} DH</p>
                                                </>
                                                :
                                                <p className="product-details-prix mt-3 mb-5">{productPrice} DH</p>
                                            }
                                            {/* <p className='product-details-prix mt-3 mb-5'>{getProductPrice(product)} DH</p> */}
                                            <hr className='hr-tag' />

                                            <div className="product-details-total">
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-6 col-6 product-details-total-text">
                                                        Prix total :
                                                    </div>
                                                    <div className="col-lg-6 col-md-6 col-6 product-details-total-price">
                                                        {
                                                            cardItemQuantity > 0 ?
                                                                `${productPrice * Number(cardItemQuantity)} DH`
                                                                :
                                                                `${productPrice} DH`
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <hr className='hr-tag' /> */}

                                            <div className="row align-items-center mt-5">
                                                <div className="col-md-4 col-lg-4 col-xl-4 col-12 mb-2">
                                                    <div className="shopping-card-item-quantity align-items-center text-center">
                                                        <span className="align-items-center fs-4" onClick={() => decrement()}>
                                                            <HiMinusSm className='shopping-card-item-quantity-icon' />
                                                        </span>
                                                        <span className="align-items-center px-2">{cardItemQuantity}</span>
                                                        <span className="align-items-center fs-4" onClick={() => increment()}>
                                                            <BiPlus className='shopping-card-item-quantity-icon' />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 col-lg-8 col-xl-8 col-12">
                                                    {/* <button onClick={() => addToCart(product.id, productPrice, 'product', cardItemQuantity)} type="submit" className="d-none btn addtocard-btn">
                                            <FiShoppingCart className='modal-card-icon' />Ajouter au panier
                                        </button> */}
                                                    <SubmitButton
                                                        btnLabel="Ajouter au panier"
                                                        content={<FiShoppingCart className='addtocart-icon' />}
                                                        onClick={() => addToCart(product.id, productPrice, 'product', cardItemQuantity, product.tarif_regulier)}
                                                        className="mt-1" />
                                                </div>

                                            </div>

                                            <hr className='hr-tag' />

                                            {product.description && product.description !== null ?
                                                <div className="product-details-desc-parent">
                                                    <p className='product-details-desc' dangerouslySetInnerHTML={{ __html: product.description.replaceAll('\\n', "<br>") }}></p>
                                                </div>
                                                :
                                                null
                                            }

                                            <hr className='hr-tag' />
                                            <ShareButtons product={product} entity={'product'} />
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className="container mb-5 pb-5 d-non desc-section">
                                <hr className='hr-tag' />

                                <div className="row mt-5">
                                    <div className="col-xl-5 col-lg-5 col-md-12 col-12">
                                        <ReviewForm productId={product.id} isPack={false} />
                                    </div>
                                    {/* <div className="col-xl-7 col-lg-7 col-md-12 col-12">
                            <div className="col-12 d-flex justify-content-center">
                                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                    <li className="nav-item d-none" role="presentation">
                                        <button className="btn desc-section-title" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">INFORMATIONS COMPLÉMENTAIRES</button>
                                    </li>

                                </ul>
                            </div>

                        </div> */}

                                    <div className={`col-xl-7 col-lg-7 col-md-12 col-12 ${windowWidth > 992 ? 'mt-3' : ''}`}>
                                        <div className="col-12 d-flex justify-content-start">
                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                {product.infoComplementaire && product.infoComplementaire !== null ?
                                                    <li className="nav-item" role="presentation">
                                                        <button className="btn desc-section-title active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">INFORMATIONS COMPLÉMENTAIRES</button>
                                                    </li>
                                                    :
                                                    ''
                                                }
                                                {/* <li className="nav-item d-none" role="presentation">
                                        <button className="btn desc-section-title" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">DESCRIPTION</button>
                                    </li> */}

                                            </ul>
                                        </div>
                                        {product.infoComplementaire && product.infoComplementaire !== null ?
                                            <div className="col-lg-8 col-xl-8 col-md-8 col-10 desc-col d-non">
                                                <div className="tab-content" id="pills-tabContent">
                                                    <div className="tab-pane fade show active"
                                                        id="pills-home"
                                                        role="tabpanel"
                                                        aria-labelledby="pills-home-tab"
                                                        tabIndex={0}
                                                    >
                                                        <div dangerouslySetInnerHTML={{ __html: product.infoComplementaire.replaceAll('\\n', "<br>") }} className='product-description'></div>

                                                    </div>
                                                    {/* <div className="tab-pane fade d-none" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex={0}>
                                </div> */}
                                                </div>
                                            </div>
                                            :
                                            ''
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                        <Footer />
                    </React.Fragment>
            }
        </>
    );
};

export default memo(ProductDetails);