import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { useLocalStorage } from "../hooks/useLocalStorage"
import { useProductIds } from "../hooks/useProductIds";
import { AddingNotify, DeleteNotify, notify } from "../helpers/Toastify";

type ShoppingCartProviderProps = {
  children: ReactNode
}

type CartItem = {
  product: number | string,
  pack?: number | string
  quantity: number,
  price: number,
  originPrice?: number,
  itemtype?: string
}

interface OrderContextType {
  paymentMethod: string;
  totalPrice: number;
}

type ShoppingCartContext = {
  getItemQuantity: (product: number | string) => number
  incrementCartQuantity: (product: number | string, price: number) => void
  decrementCartQuantity: (product: number | string) => void
  removeFromCart: (product: number | string) => void
  addToCart: (product: number | string, price: number, itemtype?: string, quantity?: number, originPrice?: number) => void
  emptyShoppingCart: () => void;
  cartQuantity: number
  cartItems: CartItem[],
  total: number;
  orderData: OrderContextType;
  setOrderData: React.Dispatch<React.SetStateAction<OrderContextType>>;
  updateCartItemsWithPromotions: () => void;
}


const ShoppingCartContext = createContext({} as ShoppingCartContext)

export function useShoppingCart() {
  return useContext(ShoppingCartContext)
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [cartItems, setCartItems] = useLocalStorage<CartItem[]>(
    "shopping-cart",
    []
  )
  const [orderData, setOrderData] = useState<OrderContextType>({
    paymentMethod: '',
    totalPrice: 0,
  });


  const cartQuantity = cartItems.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  )

  const { productIds, isProductIds } = useProductIds();
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const newTotal = cartItems.reduce(
      (sum, item) => sum + item.quantity * item.price,
      0
    );
    setTotal(newTotal);
  }, [cartItems]);


  const notifySuccess = (entity: string) => {
    AddingNotify(`${entity}`);
  };

  const notifyRemove = (entity: string) => {
    notify(`${entity} supprimé avec succès`);
  };


  function getItemQuantity(product: number | string) {
    return cartItems.find(item => item.product === product)?.quantity || 0
  }

  function addToCart(product: number | string, price: number, itemtype?: string, quantity: number = 1, originPrice?: number) {
    const pType = typeof (product);
    setCartItems((currItems) => {
      const existingItem = currItems.find((item) => item.product === product);
      if (existingItem) {
        return currItems.map((item) =>
          item.product === product ? { ...item, quantity: item.quantity + quantity, itemtype: itemtype, originPrice: originPrice } : item
        );
      } else {
        return [...currItems, { product, quantity, price, itemtype, originPrice }];
      }
    });
    notifySuccess(`${pType === 'string' ? 'Pack' : 'Produit'}`);
  }

  function incrementCartQuantity(product: number | string, price: number) {
    setCartItems(currItems => {
      if (currItems.find(item => item.product === product) == null) {
        return [...currItems, { product, quantity: 1, price }]
      } else {
        return currItems.map(item => {
          if (item.product === product) {
            return { ...item, quantity: item.quantity + 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function decrementCartQuantity(product: number | string) {
    setCartItems(currItems => {
      if (currItems.find(item => item.product === product)?.quantity === 1) {
        return currItems.filter(item => item.product !== product)
      } else {
        return currItems.map(item => {
          if (item.product === product) {
            return { ...item, quantity: item.quantity - 1 }
          } else {
            return item
          }
        })
      }
    })
  }
  function removeFromCart(product: number | string) {
    const pType = typeof (product);
    setCartItems(currItems => {
      return currItems.filter(item => item.product !== product)
    })
    notifyRemove(`${pType === 'string' ? 'Pack' : 'Produit'}`);
  }

  function emptyShoppingCart() {
    setCartItems([]);
  }

  const updateCartItemsWithPromotionsOld = () => {
    if (isProductIds) {
      const updatedCartItems = cartItems.map((item) => {
        const productHasPromotion = productIds.includes(Number(item.product));
        if (!productHasPromotion && item.itemtype === 'product') {
          return {
            ...item,
            price: item.originPrice || item.price ,
          };
        }
        return item;
      });
      setCartItems(updatedCartItems);
    }
  };

  const updateCartItemsWithPromotions = () => {
    if (isProductIds) {
      const updatedCartItems = cartItems.map((item) => {
        const productHasPromotion = productIds.includes(Number(item.product));
        if (!productHasPromotion && item.itemtype === 'product') {
          // Calculate the difference between the current price and the original price
          const priceDifference = (item.price - (item.originPrice || item.price)) * item.quantity;
  
          // Update the totalPrice in orderData by subtracting the difference
          setOrderData((prevOrderData) => ({
            ...prevOrderData,
            totalPrice: prevOrderData.totalPrice - priceDifference,
          }));
  
          // Update the item's price to its original price
          return {
            ...item,
            price: item.originPrice || item.price,
          };
        }
        return item;
      });
  
      // Update the cart items with the newly updated items
      setCartItems(updatedCartItems);
    }
  };
  

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        addToCart,
        incrementCartQuantity,
        decrementCartQuantity,
        removeFromCart,
        emptyShoppingCart,
        cartItems,
        cartQuantity,
        total,
        orderData,
        setOrderData,
        updateCartItemsWithPromotions
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  )
}