import './App.css';
import './assets/sass/App.scss';
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from 'react-query';
import ReactGA from "react-ga4";
import { Routing } from './app/routing/Routing';
import { BackToTop } from './app/components/BackToTop/BackToTop';
import { WhatsappWidget } from './app/components/WhatsappWidget/WhatsappWidget';
import { WindowWidthProvider } from './app/contexts/WindowWidthProvider';
import { CategoriesProvider } from './app/contexts/CategoriesContext';
import { ProductsProvider } from './app/contexts/ProductContext';
import { PacksProvider } from './app/contexts/PacksContext';
import { MarquesProvider } from './app/contexts/MarquesContext';
import { ShoppingCartProvider } from './app/contexts/ShoppingCartContext';
import { ToastContainer, toast } from 'react-toastify';
import { HelmetProvider } from 'react-helmet-async';
import { ShoppingCartDataProvider } from './app/contexts/ShoppingCartDataContext';
import { SlidersProvider } from './app/contexts/SliderContext';
import { OrderRefProvider } from './app/contexts/OrderRefContext';

function App() {
  const queryClient = new QueryClient();
  ReactGA.initialize("G-596RD0WFNK");
  ReactGA.send({ hitType: "pageview", page: '/', title: "Home Page" });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <WindowWidthProvider>
          <SlidersProvider>
            <CategoriesProvider>
              <ProductsProvider>
                <PacksProvider>
                  <MarquesProvider>
                    <ShoppingCartProvider>
                      <ShoppingCartDataProvider>
                        <OrderRefProvider>
                          <HelmetProvider>
                            <ToastContainer limit={5} />
                            <Routing />
                            <BackToTop />
                            <WhatsappWidget />
                          </HelmetProvider>
                        </OrderRefProvider>
                      </ShoppingCartDataProvider>
                    </ShoppingCartProvider>
                  </MarquesProvider>
                </PacksProvider>
              </ProductsProvider>
            </CategoriesProvider>
          </SlidersProvider>
        </WindowWidthProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;